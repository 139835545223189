import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/CoinFalcon Logo.png";
import "../GlobalCss.css";

const SignupHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between p-4 md:p-8">
      <a href="/" className="flex items-center">
        <img src={Logo} alt="Logo" className="h-12 w-12 " />
        <span
          className=" text-xs md:text-sm text-black font-extrabold "
          style={{ fontFamily: "Roboto-Medium" }}
        >TOKONITE
        </span>
      </a>
      <div className="flex items-center">
        <span
          className="mr-2 md:mr-4 text-xs md:text-sm text-slate-500"
          style={{ fontFamily: "Roboto-Regular" }}
        >
          Already have an account?
        </span>
        <button
          className="border border-gray px-2 py-1 md:px-4 md:py-2 rounded text-xs md:text-sm"
          onClick={() => navigate("/login")}
          style={{ fontFamily: "Roboto-Medium" }}
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default SignupHeader;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import Logo from "../../assets/logos/CoinFalcon Logo.png";
// import "../GlobalCss.css";

// const SignupHeader = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="flex items-center justify-between p-8">
//       <a href="/" className="flex items-center">
//         <img
//           src={Logo}
//           alt="Logo"
//           className="h-8 w-8 mr-2"
//         />
//         <span className="ml-2 text-sm text-black font-extrabold mr-10" style={{ fontFamily: 'Roboto-Medium' }}>COINFALCON</span>
//       </a>
//       <div className="flex items-center">
//         <span className="mr-4 text-slate-500"  style={{ fontFamily: 'Roboto-Regular' }}>Already have an account?</span>
//         <button
//           className="border border-gray  px-4 py-2 rounded text-sm"
//           onClick={() => navigate('/login')}
//           style={{ fontFamily: 'Roboto-Medium' }}
//         >
//           Log in
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SignupHeader;
