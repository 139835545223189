import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { FaUserCircle } from 'react-icons/fa';

const HomeCustomerSlider = () => {
  const testimonials = [
    {
      name: 'John Doe',
      review: 'This product changed my life! Highly recommended.',
    },
    {
      name: 'Jane Smith',
      review: 'Amazing service, great quality!',
    },
    {
      name: 'Sam Wilson',
      review: 'I love it! Will definitely use this again.',
    },
    {
      name: 'Emily Johnson',
      review: 'Fantastic experience, really happy with the purchase.',
    },
    {
      name: 'Michael Brown',
      review: 'Very professional and friendly service.',
    },
    {
      name: 'Laura Davis',
      review: 'Exceeded my expectations! Will come back again.',
    },
    {
      name: 'Kevin Martin',
      review: 'Highly efficient and well-organized.',
    },
    {
      name: 'Lisa Walker',
      review: 'Couldn’t be more satisfied with the product!',
    },
    {
      name: 'Paul Anderson',
      review: 'Fast delivery and excellent quality.',
    },
    {
      name: 'Sarah Thompson',
      review: 'Customer service was outstanding.',
    },
  ];

  return (
    <div className="bg-gray-100 py-6 md:py-12">
      <div className="container mx-auto px-4">
        <h3 className="text-3xl md:text-4xl font-semibold text-center mb-6 md:mb-10" style={{ fontFamily: 'Roboto-Black' }}>
          Genuine Customer Reviews
        </h3>
        <Splide
          options={{
            type: 'loop',
            perPage: 4,
            perMove: 1,
            gap: '1rem',
            autoplay: true,
            pauseOnHover: true,
            resetProgress: false,
            arrows: true,
            pagination: true,
            breakpoints: {
              640: {
                perPage: 1,
              },
              768: {
                perPage: 2,
              },
              1024: {
                perPage: 3,
              },
              1280: {
                perPage: 4,
              },
            },
          }}
          aria-label="Customer Reviews"
        >
          {testimonials.map((testimonial, index) => (
            <SplideSlide key={index}>
              <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg max-w-xs md:max-w-md mx-auto">
                <div className="flex flex-col md:flex-row items-start md:items-center text-center md:text-left">
                  <FaUserCircle className="w-12 h-12 md:w-16 md:h-16 mb-4 md:mb-0 mx-auto md:mx-0" style={{ color: '#1AB6FF' }} />
                  <div>
                    <h3 className="font-bold text-base md:text-lg mb-2">{testimonial.name}</h3>
                    <p className="text-gray-600 text-sm md:text-md">{testimonial.review}</p>
                  </div>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default HomeCustomerSlider;




// import React from 'react';
// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import '@splidejs/splide/dist/css/splide.min.css';
// import { FaUserCircle } from 'react-icons/fa';

// const HomeCustomerSlider = () => {
//   const testimonials = [
//     {
//       name: 'John Doe',
//       review: 'This product changed my life! Highly recommended.',
//     },
//     {
//       name: 'Jane Smith',
//       review: 'Amazing service, great quality!',
//     },
//     {
//       name: 'Sam Wilson',
//       review: 'I love it! Will definitely use this again.',
//     },
//     {
//       name: 'Emily Johnson',
//       review: 'Fantastic experience, really happy with the purchase.',
//     },
//     {
//       name: 'Michael Brown',
//       review: 'Very professional and friendly service.',
//     },
//     {
//       name: 'Laura Davis',
//       review: 'Exceeded my expectations! Will come back again.',
//     },
//     {
//       name: 'Kevin Martin',
//       review: 'Highly efficient and well-organized.',
//     },
//     {
//       name: 'Lisa Walker',
//       review: 'Couldn’t be more satisfied with the product!',
//     },
//     {
//       name: 'Paul Anderson',
//       review: 'Fast delivery and excellent quality.',
//     },
//     {
//       name: 'Sarah Thompson',
//       review: 'Customer service was outstanding.',
//     },
//   ];

//   return (
//     <div className="bg-gray-100 py-12">
//       <div className="container mx-auto">
//         <h3 className="text-4xl font-semibold text-center mb-10 " style={{ fontFamily: 'Roboto-Black' }}>
//           Genuine Customer Reviews
//         </h3>
//         <Splide
//           options={{
//             type: 'loop',
//             perPage: 4,
//             perMove: 1,
//             gap: '1rem',
//             autoplay: true,
//             pauseOnHover: true,
//             resetProgress: false,
//             arrows: true,
//             pagination: true,
//             breakpoints: {
//               640: {
//                 perPage: 1,
//               },
//             },
//           }}
//           aria-label="Customer Reviews"
//         >
//           {testimonials.map((testimonial, index) => (
//             <SplideSlide key={index}>
//               <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
//                 <div className="flex items-center">
//                   <FaUserCircle className="w-16 h-16  mr-4" style={{ color: '#1AB6FF' }} />
//                   <div>
//                     <h3 className="font-bold text-lg">{testimonial.name}</h3>
//                     <p className="text-gray-600">{testimonial.review}</p>
//                   </div>
//                 </div>
//               </div>
//             </SplideSlide>
//           ))}
//         </Splide>
//       </div>
//     </div>
//   );
// };

// export default HomeCustomerSlider;
