import React from 'react';
import Arrows from "../../assets/others/arrows.png";
import Euro from "../../assets/others/euro 1.png";
import LockGray from "../../assets/others/lock gray.png";
import LockYellow from "../../assets/others/lock yellow.png";
import Percent from "../../assets/others/precent.png";
import Question from "../../assets/others/question mark.png";
import { FaCheckCircle } from 'react-icons/fa'; 
import "../GlobalCss.css";

const HomeBitcoinMining = () => {
  const cryptoData = [
    {
      image: Arrows,
      title: 'Electricity cost',
      description: 'If you want to mine BTC on your own, then be ready to pay sky-high electricity bills regularly.',
    },
    {
      image: Percent,
      title: 'Premises',
      description: 'You need spacious non-residential premises because BTC mining is very noisy and uneasy.',
    },
    {
      image: Euro,
      title: 'Cooling and ventilation',
      description: 'These 2 components are mandatory to ensure the required cleanness and air humidity level.',
    },
    {
      image: Question,
      title: 'Legality',
      description: 'Not every country welcomes this type of earnings, thus creating additional obstacles for miners.',
    },
    {
      image: LockYellow,
      title: 'Non-stop monitoring 24/7/365',
      description: 'You need to constantly control the mining process in order not to lose your money and hardware.',
    },
    {
      image: LockYellow,
      title: 'Technical skills',
      description: 'You need an in-depth knowledge to set up your crypto mining hardware and upgrade it regularly.',
    },
  ];

  return (
    <div className='flex flex-col items-center justify-end mt-10 md:mt-20'>
      <h6 className="text-lg md:text-2xl font-semibold mb-4 text-center px-4" style={{ fontFamily: 'Roboto-Black' }}>
        For stable performance in Bitcoin mining, you need to take into account the following:
      </h6>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-6 md:px-20">
          {cryptoData.map((item, index) => (
            <div key={index} className="flex flex-col items-center justify-center p-4 md:p-6 text-center">
              <div className="flex items-center mb-2">
                <FaCheckCircle className="mr-2" style={{ color: '#1AB6FF' }} /> 
                <h2 className="text-md md:text-lg font-semibold" style={{ fontFamily: 'Roboto-Black' }}>
                  {item.title}
                </h2>
              </div>
              <p className="text-gray-600 text-sm md:text-md text-slate-500" style={{ fontFamily: 'Roboto-Regular' }}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBitcoinMining;





// import React from 'react';
// import Arrows from "../../assets/others/arrows.png";
// import Euro from "../../assets/others/euro 1.png";
// import LockGray from "../../assets/others/lock gray.png";
// import LockYellow from "../../assets/others/lock yellow.png";
// import Percent from "../../assets/others/precent.png";
// import Question from "../../assets/others/question mark.png";
// import { FaCheckCircle } from 'react-icons/fa'; 
// import "../GlobalCss.css";

// const HomeBitcoinMining = () => {
//   const cryptoData = [
//     {
//       image: Arrows,
//       title: 'Electricity cost',
//       description: 'If you want to mine BTC on your own, then be ready to pay sky-high electricity bills regularly.',
//     },
//     {
//       image: Percent,
//       title: 'Premises',
//       description: 'You need spacious non-residential premises because BTC mining is very noisy and uneasy.',
//     },
//     {
//       image: Euro,
//       title: 'Cooling and ventilation',
//       description: 'These 2 components are mandatory to ensure the required cleanness and air humidity level.',
//     },
//     {
//       image: Question,
//       title: 'Legality',
//       description: 'Not every country welcomes this type of earnings, thus creating additional obstacles for miners.',
//     },
//     {
//       image: LockYellow,
//       title: 'Non-stop monitoring 24/7/365',
//       description: 'You need to constantly control the mining process in order not to lose your money and hardware.',
//     },
//     {
//       image: LockYellow,
//       title: 'Technical skills',
//       description: 'You need an in-depth knowledge to set up your crypto mining hardware and upgrade it regularly.',
//     },
//   ];

//   return (
//     <div className='flex flex-col items-center justify-end mt-20'>
//       <h6 className="text-2xl font-semibold mb-2" style={{ fontFamily: 'Roboto-Black' }}>
//         For stable performance in Bitcoin mining, you need to take into account the following:
//       </h6>
//       <div className="container mx-auto mt-8">
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pr-40 pl-40">
//           {cryptoData.map((item, index) => (
//             <div key={index} className="flex flex-col items-center justify-center p-6">
//               <div className="flex items-center mb-2">
//                 <FaCheckCircle className=" mr-2" style={{ color: '#1AB6FF' }}/> 
//                 <h2 className="text-lg font-semibold" style={{ fontFamily: 'Roboto-Black' }}>
//                   {item.title}
//                 </h2>
//               </div>
//               <p className="text-gray-600 text-center text-slate-500 text-md" style={{ fontFamily: 'Roboto-Regular' }}>
//                 {item.description}
//               </p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeBitcoinMining;



// import React from 'react';
// import Arrows from "../../assets/others/arrows.png";
// import Euro from "../../assets/others/euro 1.png";
// import LockGray from "../../assets/others/lock gray.png";
// import LockYellow from "../../assets/others/lock yellow.png";
// import Percent from "../../assets/others/precent.png";
// import Question from "../../assets/others/question mark.png";
// import "../GlobalCss.css";

// const HomeBitcoinMining = () => {
//   const cryptoData = [
//     {
//       image: Arrows,
//       title: 'Electricity cost',
//       description: 'If you want to mine BTC on your own, then be ready to pay sky-high electricity bills regularly.',
//     },
//     {
//       image: Percent,
//       title: 'Premises',
//       description: 'You need spacious non-residential premises because BTC mining is very noisy and uneasy.',
//     },
//     {
//       image: Euro,
//       title: 'Cooling and ventilation',
//       description: 'These 2 components are mandatory to ensure the required cleanness and air humidity level.',
//     },
//     {
//       image: Question,
//       title: 'Legality',
//       description: 'Not every country welcomes this type of earnings, thus creating additional obstacles for miners.',
//     },
//     {
//       image: LockYellow,
//       title: 'Non-stop monitoring 24/7/365',
//       description: 'You need to constantly control the mining process in order not to lose your money and hardware.',
//     },
//     {
//         image: LockYellow,
//         title: 'Technical skills',
//         description: 'You need an in-depth knowledge to set up your crypto mining hardware and upgrade it regularly.',
//       },
  
//   ];

//   return (
//     <div className='flex flex-col items-center justify-end mt-20'>
//       <h6 className="text-2xl font-semibold mb-2" style={{ fontFamily: 'Roboto-Black' }}>For stable performance in Bitcoin mining, you need to take into account the following:</h6>
//       <div className="container mx-auto mt-8">
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pr-40 pl-40">
//           {cryptoData.map((item, index) => (
//             <div key={index} className="flex flex-col items-center justify-center p-6 ">
//               <h2 className="text-lg font-semibold mb-2" style={{ fontFamily: 'Roboto-Black' }}>{item.title}</h2>
//               <p className="text-gray-600 text-center text-slate-500 text-md" style={{ fontFamily: 'Roboto-Regular' }}>{item.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeBitcoinMining;