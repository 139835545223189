import React, { useState , useEffect } from 'react';
import { jwtDecode} from "jwt-decode"; 
import { useNavigate } from "react-router-dom";

const TransfersForm = () => {
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); 
  const token = localStorage.getItem('accessToken'); 
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessage('');
    setMessageType('');

    const data = {
      email,
      amount,
    };

    try {
      const response = await fetch(`${apiUrl}/user/transfers/send`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Network response was not ok');
      }

      setMessage(result.message || 'Transfer successful!');
      setMessageType('success');
      setEmail('');
      setAmount('');
    } catch (error) {
      setMessage(error.message || 'Transfer failed');
      setMessageType('error');
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 28800;
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem('accessToken');
          navigate('/');
        }
         
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.removeItem('accessToken');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate, token]);

  return (
    <div className="flex items-center justify-center py-6">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold mb-6 text-center" style={{ fontFamily: 'Roboto-Medium' }}>
          Transfer Form
        </h2>
        {message && (
          <p className={`text-center mb-4 ${messageType === 'success' ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </p>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-1">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Enter email"
            />
          </div>

          <div>
            <label htmlFor="amount" className="block text-sm font-medium mb-1">
              Amount
            </label>
            <input
              id="amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Enter amount"
            />
          </div>

          <div>
            <button
              type="submit"
              className="w-full py-2 text-white rounded-md hover:bg-blue-700 transition duration-200 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              style={{ fontFamily: 'Roboto-Medium', background: '#1AB6FF' }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransfersForm;


