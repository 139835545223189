import React from "react";
import LoginHeader from "../components/Login/loginHeader";
import ForgetPassword from "../components/forgetPassword";
import ConfirmPassword from "../components/confirmPassword";

const Confirm = () => {
  return (
    <div className="w-full bg-white" >
        <LoginHeader />
        <ConfirmPassword />
    </div>
  );
};

export default Confirm;