import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 

const LogoutModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); 

  const handleLogout = () => {
    setIsLoading(true);
    
    localStorage.removeItem('accessToken');
    setIsLoading(false);
    onClose(); 
    navigate('/'); 
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="relative z-50 bg-white rounded-lg shadow-lg p-6 w-80">
            <h2 className="text-xl font-semibold mb-4">Confirm Logout</h2>
            <p className="mb-4">Are you sure you want to logout?</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-300"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2  text-white rounded hover:bg-orange-600 focus:outline-none focus:ring focus:ring-sky-600 hover:bg-blue-400"
                onClick={handleLogout}
                disabled={isLoading}
                style={{background:'#1AB6FF',}}
              >
                {isLoading ? 'Logging Out...' : 'Logout'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LogoutModal;