import React from "react";
import "../GlobalCss.css";
import { useNavigate } from "react-router-dom";

const HomeBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center text-black p-4">
      <div className="text-center max-w-4xl mx-auto">
        <p
          className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 mt-12 sm:mt-16 md:mt-24 leading-tight"
          style={{ fontFamily: "Roboto-Black" }}
        >
          Buy a Mining Contract Now and Stay Ahead of Difficulty Decreases in
          2024
        </p>
        <p
          className="text-lg sm:text-xl md:text-2xl text-slate-500 mb-10 md:mb-16"
          style={{ fontFamily: "Roboto-Regular" }}
        >
          Tokonite is the easiest place to mine Bitcoin. Start mining BTC effortlessly within minutes.
        </p>
        <button
          className="text-white text-sm sm:text-md font-bold px-8 py-3 sm:px-10 sm:py-4 md:px-16 md:py-5 rounded-sm hover:bg-sky-400"
          style={{ fontFamily: "Roboto-Regular", background: "#1AB6FF" }}
        >
          <a href="/signup">Get Started</a>
        </button>
      </div>
    </div>
  );
};

export default HomeBanner;

// import React from 'react';
// import "../GlobalCss.css";
// import { useNavigate } from 'react-router-dom';

// const HomeBanner = () => {
//   const navigate = useNavigate();
//   return (
//     <div className="flex flex-col items-center  text-black p-4">
//       <div className="text-center max-w-4xl mx-auto">
//         <p className="text-6xl font-bold mb-8 mt-24" style={{ fontFamily: 'Roboto-Black' }}>
//            Buy a Mining Contract Now and Stay Ahead of Difficulty Decreases in 2024
//         </p>
//         <p className="text-2xl text-slate-500 mb-16 " style={{ fontFamily: 'Roboto-Regular' }}>
//         CoinFalcon is the easiest place to buy and sell cryptocurrency. Buy Bitcoin, Ethereum and other digital assets within minutes.
//         </p>
//         <button className=" text-white text-md font-bold px-16 py-5 rounded-sm hover:bg-sky-400 mb-2"  style={{ fontFamily: 'Roboto-Regular', background:'#1AB6FF' }}>
//         <a href="/dashboard" >Get Started</a>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default HomeBanner;

// import React, { useState } from 'react';

// const HomeBanner = () => {
//   const [amount, setAmount] = useState(0);
//   const [selectedCurrency, setSelectedCurrency] = useState('BTC');

//   const handleAmountChange = (e) => {
//     setAmount(e.target.value);
//   };

//   const handleCurrencyChange = (e) => {
//     setSelectedCurrency(e.target.value);
//   };

//   const handleBuySell = () => {
//     // Implement buy/sell logic using CoinFalcon API
//     // For simplicity, let's just log the details for now
//     console.log(`Buying/Selling ${amount} ${selectedCurrency}`);
//   };

//   return (
//     <div className="max-w-md mx-auto mt-8 p-6 bg-white border rounded-md shadow-md">
//       <h2 className="text-2xl font-bold mb-4">Buy/Sell Cryptocurrency</h2>

//       <p className="text-gray-600 mb-4">
//         CoinFalcon is the easiest place to buy and sell cryptocurrency. Buy Bitcoin, Ethereum, and other digital assets within minutes.
//       </p>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-600">Amount</label>
//         <input
//           type="number"
//           value={amount}
//           onChange={handleAmountChange}
//           className="mt-1 p-2 border rounded-md w-full"
//         />
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-600">Currency</label>
//         <select
//           value={selectedCurrency}
//           onChange={handleCurrencyChange}
//           className="mt-1 p-2 border rounded-md w-full"
//         >
//           <option value="BTC">Bitcoin (BTC)</option>
//           <option value="ETH">Ethereum (ETH)</option>
//           {/* Add more options for other currencies */}
//         </select>
//       </div>

//       <button
//         onClick={handleBuySell}
//         className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//       >
//         Get Started
//       </button>
//     </div>
//   );
// };

// export default HomeBanner;
