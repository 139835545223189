import React from "react";
import PriceTable from "../components/Prices/priceTable";


const Prices = () => {
  return (
    <div className="w-full " style={{background: "#f7fafc"}} >
        <PriceTable />
      
    </div>
  );
};

export default Prices;