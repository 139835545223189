import React from 'react';
import "../GlobalCss.css";
import BTC from "../../assets/logos/bitcoin png.png";
import ETH from "../../assets/logos/Ethereum Logo.png";
import BTC_CASH from "../../assets/logos/bitcoin-cash-bch-logo (1).png";
import LITCOIN from "../../assets/logos/litecoin-ltc-logo.png";
import THETER from "../../assets/logos/tether-usdt-logo.png";
import XRP from "../../assets/logos/xrp.png";
import DOGE from "../../assets/logos/dogecoin.png";
import TRX from "../../assets/logos/tron.png";
import ADA from "../../assets/logos/cardano.png";
import GRN from "../../assets/logos/GRN-new.webp";
import ECA from "../../assets/logos/electra-logo.png";


const  PriceTable = () => {
  const data = [
    { rank: 1,image: BTC, symbol: 'BTC', name: 'Bitcoin', price: '10,830,974.34 PKR', change: '%', marketCap: '854.36B€' },
    { rank: 2,image: ETH, symbol: 'ETH', name: 'Ethereum', price: '544,581.39 PKR', change: '%', marketCap: '250.31B€' },
    { rank: 3,image: BTC_CASH, symbol: 'BCH', name: 'Bitcoin Cash', price: '36,933.62 PKR', change: '%', marketCap: '4.78B€' },
    { rank: 4,image: LITCOIN, symbol: 'LTC', name: 'Litecoin', price: '21,228.71 PKR', change: '%', marketCap: '5.42B€' },
    { rank: 5,image: THETER, symbol: 'USDT', name: 'Tether', price: '283.27 PKR', change: '%', marketCap: '89.80B€' },
    { rank: 6,image: XRP, symbol: 'XRP', name: 'XRP', price: '159.22 PKR', change: '%', marketCap: '33.58B€' },
    { rank: 7,image: DOGE, symbol: 'DOGE', name: 'Dogecoin', price: '34.77 PKR', change: '%', marketCap: '13.13B€' },
    { rank: 8,image: TRX, symbol: 'TRX', name: 'Tron', price: '22.75 PKR', change: '%', marketCap: '9.18B€' },
    { rank: 9,image: ADA, symbol: 'ADA', name: 'Cardano', price: '10.83 PKR', change: '%', marketCap: '14.93B€' },
    { rank: 10,image: GRN, symbol: 'GRN', name: 'GreenPower', price: '3.68 PKR', change: '%', marketCap: '45.19M€' },
    { rank: 11,image: ECA, symbol: 'ECA', name: 'Electra', price: '0.01083 PKR', change: '%', marketCap: '0€' },
  ];

  return (
    <div className="flex flex-col items-center pt-10 pb-10">
      <table className="bg-white border shadow-md ">
        <thead>
          <tr>
            <th colSpan="7" className="p-8">
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>Cryptocurrencies</h1>
                <div className="relative flex items-center space-x-4">
                  <input
                    type="text"
                    placeholder="Search"
                    className="border rounded py-2 px-4 pl-10 font-sm" 
                    style={{ fontFamily: 'Roboto-Regular' , background: "#f5f9fb"}}
                  />                
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3" , fontFamily: 'Roboto-Regular'}}>Rank</th>
           
            <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Name</th>
            <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Symbol</th>
            <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Price</th>
            <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Change, 24 hrs</th>
            <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Market Cap</th>
            <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Graph</th>
          </tr>
        </thead>
        <tbody>
          {data.map((crypto) => (
            <tr key={crypto.rank}>
              <td className="p-4" style={{color:"#6D859E"}}>{crypto.rank}</td>
              <td className="p-4">
                <img src={crypto.image} alt={crypto.symbol} style={{ width: '30px', height: '30px' }} />
              </td>
              <td className="p-4">{crypto.name}</td>
              <td className="p-4" style={{color:"#6D859E"}}>{crypto.symbol}</td>
              <td className="p-4">{crypto.price}</td>
              <td className="p-4" style={{color:"#2FC569"}}>{crypto.change}</td>
              <td className="p-4">{crypto.marketCap}</td>
              <td className="p-4">
                <button className=" text-white px-4 py-2 rounded" style={{color: "#1AB6FF", background:"#edfaff"}}>Buy</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default  PriceTable;

// import React from 'react';
// import "../GlobalCss.css";
// import BTC from "../../assets/logos/bitcoin png.png";
// import ETH from "../../assets/logos/Ethereum Logo.png";
// import BTC_CASH from "../../assets/logos/bitcoin-cash-bch-logo (1).png";
// import LITCOIN from "../../assets/logos/litecoin-ltc-logo.png";
// import THETER from "../../assets/logos/tether-usdt-logo.png";
// import XRP from "../../assets/logos/xrp.png";
// import DOGE from "../../assets/logos/dogecoin.png";
// import TRX from "../../assets/logos/tron.png";
// import ADA from "../../assets/logos/cardano.png";
// import GRN from "../../assets/logos/GRN-new.webp";
// import ECA from "../../assets/logos/electra-logo.png";


// const  PriceTable = () => {
//   const data = [
//     { rank: 1,image: BTC, symbol: 'BTC', name: 'Bitcoin', price: '10,830,974.34 PKR', change: '%', marketCap: '854.36B€' },
//     { rank: 2,image: ETH, symbol: 'ETH', name: 'Ethereum', price: '544,581.39 PKR', change: '%', marketCap: '250.31B€' },
//     { rank: 3,image: BTC_CASH, symbol: 'BCH', name: 'Bitcoin Cash', price: '36,933.62 PKR', change: '%', marketCap: '4.78B€' },
//     { rank: 4,image: LITCOIN, symbol: 'LTC', name: 'Litecoin', price: '21,228.71 PKR', change: '%', marketCap: '5.42B€' },
//     { rank: 5,image: THETER, symbol: 'USDT', name: 'Tether', price: '283.27 PKR', change: '%', marketCap: '89.80B€' },
//     { rank: 6,image: XRP, symbol: 'XRP', name: 'XRP', price: '159.22 PKR', change: '%', marketCap: '33.58B€' },
//     { rank: 7,image: DOGE, symbol: 'DOGE', name: 'Dogecoin', price: '34.77 PKR', change: '%', marketCap: '13.13B€' },
//     { rank: 8,image: TRX, symbol: 'TRX', name: 'Tron', price: '22.75 PKR', change: '%', marketCap: '9.18B€' },
//     { rank: 9,image: ADA, symbol: 'ADA', name: 'Cardano', price: '10.83 PKR', change: '%', marketCap: '14.93B€' },
//     { rank: 10,image: GRN, symbol: 'GRN', name: 'GreenPower', price: '3.68 PKR', change: '%', marketCap: '45.19M€' },
//     { rank: 11,image: ECA, symbol: 'ECA', name: 'Electra', price: '0.01083 PKR', change: '%', marketCap: '0€' },
//   ];

//   return (
//     <div className="flex flex-col items-center">
//       <table className="bg-white border shadow-md ">
//         <thead>
//           <tr>
//             <th colSpan="7" className="p-8">
//               <div className="flex justify-between">
//                 <h1 className="text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>Cryptocurrencies</h1>
//                 <div className="relative flex items-center space-x-4">
//                   <input
//                     type="text"
//                     placeholder="Search"
//                     className="border rounded py-2 px-4 pl-10 font-sm" 
//                     style={{ fontFamily: 'Roboto-Regular' , background: "#f5f9fb"}}
//                   />                
//                 </div>
//               </div>
//             </th>
//           </tr>
//           <tr>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3" , fontFamily: 'Roboto-Regular'}}>Rank</th>
           
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Name</th>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Symbol</th>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Price</th>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Change, 24 hrs</th>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Market Cap</th>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Graph</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((crypto) => (
//             <tr key={crypto.rank}>
//               <td className="p-4" style={{color:"#6D859E"}}>{crypto.rank}</td>
//               <td className="p-4">
//                 <img src={crypto.image} alt={crypto.symbol} style={{ width: '30px', height: '30px' }} />
//               </td>
//               <td className="p-4">{crypto.name}</td>
//               <td className="p-4" style={{color:"#6D859E"}}>{crypto.symbol}</td>
//               <td className="p-4">{crypto.price}</td>
//               <td className="p-4" style={{color:"#2FC569"}}>{crypto.change}</td>
//               <td className="p-4">{crypto.marketCap}</td>
//               <td className="p-4">
//                 <button className=" text-white px-4 py-2 rounded" style={{color: "#1AB6FF", background:"#edfaff"}}>Buy</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default  PriceTable;
