import React, { useState } from 'react';
import Logo from "../assets/logos/CoinFalcon Logo.png";
import LogoutModal from './logoutModal';
import { FaBars, FaTimes } from "react-icons/fa";

const DashboardHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="bg-white">
      <div className="container mx-auto p-4 flex items-center justify-between">
        <a href="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="w-12 h-12" />
          <span
            className="text-xs sm:text-sm text-black font-extrabold"
            style={{ fontFamily: 'Roboto-Medium' }}
          >
            TOKONITE
          </span>
        </a>

        <div className="flex items-center space-x-4">
          <div className="relative inline-block text-left">
            <button
              onClick={toggleDropdown}
              className="flex items-center justify-center w-8 h-8 hover:text-blue-500 sm:mt-2"
              aria-expanded={isDropdownOpen}
              style={{ color: "#1AB6FF" }}
            >
              <span className="flex justify-center w-full">
                {isDropdownOpen ? (
                  <FaTimes size={24} />
                ) : (
                  <FaBars size={24} />
                )}
              </span>
            </button>
            {isDropdownOpen && (
              <div className="origin-top-right absolute right-0 mt-4 w-48 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1">
                  <a
                    href="/statistics"
                    className="block px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex"
                  >
                    Dashboard
                  </a>
                  <a
                    href="/transfers"
                    className="block px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex"
                  >
                    Transfers
                  </a>
                  <a
                    href="/setting"
                    className="block px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex"
                  >
                    Setting
                  </a>
                  <button
                    onClick={openModal}
                    className="block w-full text-left px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex"
                  >
                    Logout
                  </button>
                  <LogoutModal isOpen={isModalOpen} onClose={closeModal} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;




// import React, { useState } from 'react';
// import Logo from "../assets/logos/CoinFalcon Logo.png";
// import LogoutModal from './logoutModal';
// import { FaBars, FaTimes } from "react-icons/fa";


// const DashboardHeader = () => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
 
//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };


  
//   return (
//     <header className="bg-white">
//       <div className="container mx-auto p-4 flex items-center justify-between">
        
//           <a href="/" className='flex items-center'>
//             <img
//               src={Logo}
//               alt="Logo"
//               className="w-12 h-12"
//             />
//             <span className=" text-xs sm:text-sm text-black font-extrabold" style={{ fontFamily: 'Roboto-Medium' }}>TOKONITE</span>
//           </a>
        
//         <div className="flex items-center space-x-4">
      
//             <div className="relative inline-block text-left">
//             <button
//                 onClick={toggleDropdown}
//                 className=" hover:text-blue-500 sm:mt-2"
//                 aria-expanded={isDropdownOpen}
//                 style={{ color: "#1AB6FF" }}
//               >
//             {isDropdownOpen ? (
//             <FaTimes size={24} />
//           ) : (
//             <FaBars size={24} style={{ color: "#1AB6FF" }} />
//           )}
//           </button>
//               {isDropdownOpen && (
//                 <div className="origin-top-right absolute right-0 mt-4 w-48 rounded-md shadow-lg  ring-1 ring-black ring-opacity-5 z-50">
//               <div className="py-1">
//                 <a href="/statistics" className="block px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                    Dashboard
//                 </a>
//                 <a href="/setting" className="block px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                   Setting
//                 </a>
//                 <button onClick={openModal} className="block w-full text-left px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                   Logout
//                 </button>
//                 <LogoutModal isOpen={isModalOpen} onClose={closeModal} />
//               </div>
//             </div>
              
//               )}
//             </div>
      
//         </div>
//       </div>
//     </header>
//   );
// };

// export default DashboardHeader;




// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom'; 
// import Logo from "../assets/logos/CoinFalcon Logo.png";
// import "./GlobalCss.css";
// import LogoutModal from './logoutModal';

// const DashboardHeader = () => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const navigate = useNavigate(); 
//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div className="flex items-center justify-between p-4 md:p-8 w-full bg-white">
//       <a href="/" className="flex items-center">
//         <img
//           src={Logo}
//           alt="Logo"
//           className="w-12 h-12" 
//         />
//         <span className=" text-xs sm:text-sm text-black font-extrabold" style={{ fontFamily: 'Roboto-Medium' }}>
//         TOKONITE
//         </span>
//       </a>   
//       <div className="flex items-center">
//         <div className="relative inline-block text-left">
//           <button
//             onClick={toggleDropdown}
//             className="text-black hover:text-orange-500"
//             aria-expanded={isDropdownOpen}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-4 w-4 md:h-6 md:w-6"
//               fill="#1AB6FF"
//               viewBox="0 0 24 24"
//               stroke="#1AB6FF"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M4 6h16M4 12h16M4 18h16"
//               />
//             </svg>
//           </button>
//           {isDropdownOpen && (
//             <div className="origin-top-right absolute right-0 mt-4 w-48 rounded-md shadow-lg  ring-1 ring-black ring-opacity-5 z-50">
//               <div className="py-1">
//                 <a href="/statistics" className="block px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                    Dashboard
//                 </a>
//                 <a href="/setting" className="block px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                   Setting
//                 </a>
//                 <button onClick={openModal} className="block w-full text-left px-4 py-2 text-sm md:text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                   Logout
//                 </button>
//                 <LogoutModal isOpen={isModalOpen} onClose={closeModal} />
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DashboardHeader;




// import React ,{ useState } from 'react';
// import { useNavigate } from 'react-router-dom'; 
// import Logo from "../assets/logos/CoinFalcon Logo.png";
// import "./GlobalCss.css";

// import LogoutModal from './logoutModal';

// const DashboardHeader = () => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const navigate = useNavigate(); 
//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div className="flex items-center justify-between p-8">
//       <a href="/" className="flex items-center">
//         <img
//           src={Logo}
//           alt="Logo"
//           className="h-8 w-8 mr-2" 
//         />
//         <span className="ml-2 text-sm text-black font-extrabold mr-10" style={{ fontFamily: 'Roboto-Medium' }}>COINFALCON</span>
//       </a>   
//       <div className="flex items-center">
//       <div className="relative inline-block text-left">
//               <button
//                 onClick={toggleDropdown}
//                 className="text-white hover:text-orange-500"
//                 aria-expanded={isDropdownOpen}
                
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className="h-4 w-4 md:h-6 md:w-6"
//                   fill="#1AB6FF"
//                   viewBox="0 0 24 24"
//                   stroke="#1AB6FF"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M4 6h16M4 12h16M4 18h16"
//                   />
//                 </svg>
//               </button>
//              {isDropdownOpen && (
//                 <div className="origin-top-right absolute right-0 mt-4 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
//                   <div className="py-1">
//                     <a href="/dashboard" className="block px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                       {/* <img src={BarChart} className='h-6 w-6 mr-6' /> */}
//                       Dashboard
//                     </a>
//                     <a href="/statistics" className="block px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                       {/* <img src={ATM} className='h-6 w-6 mr-6' /> */}
//                       Statistics
//                     </a>
//                     <a href="/setting" className="block px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                       {/* <img src={CogWheels} className='h-6 w-6 mr-6' /> */}
//                       Setting
//                     </a>
//                     <a onClick={openModal} className="block px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-200 flex">
//                       {/* <img src={LogOut} className='h-6 w-6 mr-6' /> */}
//                       Logout
//                     </a>
//                     {/* <LogoutModal isOpen={isModalOpen} onClose={closeModal} /> */}
//                     <LogoutModal isOpen={isModalOpen} onClose={closeModal} />
//                   </div>
//                 </div>
//               )}
//               </div>
//       </div>
//     </div>
//   );
// };

// export default DashboardHeader;