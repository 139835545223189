import React from 'react';
import MasterCard from "../../assets/others/Untitled design (15).png";
import Sepa from "../../assets/others/Untitled design (16).png";
import Crypto from "../../assets/others/Untitled design (14).png";
import "../GlobalCss.css";

const HomeStartMining = () => {
  const commonBoxStyle = {
    width: '100%',
    maxWidth: '360px',
    height: '380px',
    padding: '1rem',
    borderRadius: '1rem',
    backgroundColor: '#f5f9fb',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div className="text-center mb-20 mt-10 px-4">
      <h3 className="text-3xl md:text-4xl font-semibold mb-6" style={{ fontFamily: 'Roboto-Black' }}>
        How to Start Bitcoin Mining?
      </h3>
      <div className="flex flex-col md:flex-row justify-center items-center gap-8 mt-8">
        <div className="flex-1" style={commonBoxStyle}>
          <div className="flex justify-center">
            <img
              src={MasterCard}
              alt="MasterCard"
              className="w-full h-auto mb-4 mt-4"
            />
          </div>
          <h2 className="text-2xl md:text-3xl font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>
            Register
          </h2>
          <p className="text-slate-500 mb-4 text-base md:text-lg" style={{ fontFamily: 'Roboto-Regular' }}>
            Complete account registration at Tokonite — it'll take just a few minutes.
          </p>
        </div>
        <div className="flex-1" style={commonBoxStyle}>
          <div className="flex justify-center">
            <img
              src={Sepa}
              alt="SEPA Transfer"
              className="w-full h-auto mb-4 mt-4"
            />
          </div>
          <h2 className="text-2xl md:text-3xl font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>
            Buy a Contract
          </h2>
          <p className="text-slate-500 mb-4 text-base md:text-lg" style={{ fontFamily: 'Roboto-Regular' }}>
            Choose the most profitable contract and pay for it using BTC, Mastercard, VISA.
          </p>
        </div>
        <div className="flex-1" style={commonBoxStyle}>
          <div className="flex justify-center">
            <img
              src={Crypto}
              alt="Credit Card"
              className="w-full h-auto mb-4 mt-4"
            />
          </div>
          <h2 className="text-2xl md:text-3xl font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>
            Get Paid
          </h2>
          <p className="text-slate-500 mb-2 text-base md:text-lg" style={{ fontFamily: 'Roboto-Regular' }}>
            Withdraw the BTC mining profit from your account balance anytime you want.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeStartMining;




// import React from 'react';
// import MasterCard from "../../assets/others/Untitled design (15).png";
// import Sepa from "../../assets/others/Untitled design (16).png";
// import Crypto from "../../assets/others/Untitled design (14).png";
// import "../GlobalCss.css";

// const HomeStartMining = () => {
//   const commonBoxStyle = {
//     width: '360px', 
//     height: '380px', 
//     padding: '1.5rem',
//     borderRadius: '1rem',
//     backgroundColor: '#f5f9fb',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//   };

//   return (
//     <div className="text-center mb-20 mt-20">
//       <h3 className="text-4xl font-semibold mb-6" style={{ fontFamily: 'Roboto-Black' }}>How to Start Bitcoin Mining?</h3>
//       <div className="flex justify-center items-center space-x-8 mt-10">
//         <div className="max-w-md" style={commonBoxStyle}>
//           <div className="flex justify-center ">
//               <img
//                 src={MasterCard}
//                 alt="MasterCard"
//                 className="w-full h-auto mb-4 mt-4 "
//               />
//           </div>
//           <h2 className="text-3xl font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>Register</h2>
//           <p className="text-slate-500 mb-8 text-lg "style={{ fontFamily: 'Roboto-Regular' }}>Сomplete account registration at Hashing24 — it'll take just a few minutes.</p>
//         </div>
//         <div className="max-w-md" style={commonBoxStyle}>
//           <div className='flex justify-center  ' >
//             <img
//               src={Sepa}
//               alt="SEPA Transfer"
//               className="w-full h-auto mb-4 mt-4"
//             />
//           </div>
//           <h2 className="text-3xl font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>Buy a Contract</h2>
//           <p className="text-slate-500 mb-4 text-lg" style={{ fontFamily: 'Roboto-Regular' }}> Choose the most profitable contract and pay for it using BTC, Mastercard, VISA.</p>
//         </div>
//         <div className="max-w-xs" style={commonBoxStyle}>
//           <div className="flex justify-center ">
//             <img
//               src={Crypto}
//               alt="Credit Card"
//               className="w-full h-auto mb-4 mt-4"
//             />
//           </div>
//           <h2 className="text-3xl font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>Get Paid</h2>
//           <p className="text-slate-500 mb-2 text-lg"style={{ fontFamily: 'Roboto-Regular' }}>Withdraw the BTC mining profit from your account balance anytime you want.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeStartMining;










// import React from 'react';
// import MasterCard from "../../assets/logos/mastercard logo.png";
// import Visa from "../../assets/logos/Visa Logo.png";
// import Sepa from "../../assets/logos/sepa logo.png";
// import Crypto from "../../assets/logos/bitcoin png.png";
// import Ethreum from "../../assets/logos/Ethereum Logo.png";

// const HomePaymentMethods = () => {
//   return (
//     <div className="text-center mt-8 mb-20">
//       <h1 className="text-5xl font-bold mb-6">Available Payment Methods</h1>
//       <h3 className="text-xl text-slate-500 mb-4">CoinFalcon lets you use Euro to buy the most popular cryptocurrencies through any of our payment methods.</h3>

//       <div className="flex justify-center items-center space-x-8">

//         <div className="flex flex-col items-center max-w-md p-12 rounded-md shadow-md mt-10 bg-white " style={{ backgroundColor: '#f5f9fb' }}>
//           <div className="flex justify-center mb-4 mt-8 ">
//             <div className='bg-white rounded-full mb-4 ' style={{ width: '76px', height: '76px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//               <img
//                 src={MasterCard}
//                 alt="MasterCard"
//                 className="w-16 h-12 mb-4 mt-4 rounded-full "
//               />
//             </div>
//             <div className='bg-white rounded-full mb-4' style={{ width: '76px', height: '76px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//               <img
//                 src={Visa}
//                 alt="Visa"
//                 className="w-16 h-12 mb-4 mt-4 rounded-full bg-blue"
//               />
//             </div>
//           </div>
//           <h2 className="text-3xl font-bold mb-2">Debit / Credit Cards</h2>
//           <p className="text-slate-500 mb-8 text-lg ">Buy cryptocurrency instantly with MasterCard / Visa</p>
//         </div>

//         <div className="flex flex-col items-center max-w-md p-12 rounded-md shadow-md mt-10 bg-white" style={{ backgroundColor: '#f5f9fb' }}>
//           <div className='bg-sky-600 rounded-full mb-8 mt-4' style={{ width: '76px', height: '76px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//             <img
//               src={Sepa}
//               alt="SEPA Transfer"
//               className="w-12 h-6"
//             />
//           </div>
//           <h2 className="text-3xl font-bold mb-2">SEPA transfer logo</h2>
//           <p className="text-slate-500 mb-8 text-lg "> SEPA available for everyone in 33 countries.</p>
//         </div>

//         <div className="flex flex-col items-center max-w-xs p-8 rounded-md shadow-md mt-10 bg-white " style={{ backgroundColor: '#f5f9fb' }}>
//           <div className="flex justify-center mb-4 mt-4">
//             <img
//               src={Crypto}
//               alt="Credit Card"
//               className="w-16 h-12 mb-4 bg-white"
//             />
//             <img
//               src={Ethreum}
//               alt="Credit Card"
//               className="w-16 h-12 mb-4"
//             />
//           </div>
//           <h2 className="text-2xl font-bold mb-2">Cryptocurrency</h2>
//           <p>Over 20 currencies you can choose from</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomePaymentMethods;


