import React from 'react';
import "../GlobalCss.css"; 

const AboutUsHeading = () => {
  return (
    <div className="flex  item-center justify-center pt-6">
      <h2 className="text-6xl font-extrabold mt-6" style={{ fontFamily: 'Roboto-Black' }}>About us</h2>
    </div>
  );
};

export default AboutUsHeading;
