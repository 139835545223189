import React from 'react';
import VideoCreditCard from '../../assets/others/wattum.webp';
import "../GlobalCss.css";

const HomeCreditCard = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center p-8 md:p-20">
      <div className="w-full md:w-1/2 md:ml-10 lg:ml-40 mb-6 md:mb-0">
        <img
          title="Crypto Video"
          className="w-full h-auto"
          src={VideoCreditCard}
          alt="Crypto Video"
        />
      </div>
      <div className="w-full md:w-1/2 md:mr-10 lg:mr-40 p-4 md:p-10">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-6" style={{ fontFamily: 'Roboto-Black' }}>
          Most Effective Large-Scale <br /> BTC Mining
        </h2>
        <p className="text-slate-500 text-lg md:text-xl mb-4" style={{ fontFamily: 'Roboto-Regular' }}>
          Wattum is the world's leading cryptocurrency mining equipment distributor and complete mining solutions provider based out of the US. Wattum provides the full spectrum of hosted cryptocurrency mining services without any related complexities of managing mining hardware or software.
        </p>
        <p className="text-slate-500 text-lg md:text-xl mb-8" style={{ fontFamily: 'Roboto-Regular' }}>
          Wattum provides turn-key macro-management in mining: software, firmware, mining pool, and mining farm management in North America and other countries all over the world. The company's products and services range from ASIC miners, mobile industrial mining containers, and power transformers.
        </p>
      </div>
    </div>
  );
};

export default HomeCreditCard;




// import React from 'react';
// import VideoCreditCard from '../../assets/others/wattum.webp';
// import "../GlobalCss.css";

// const HomeCreditCard = () => {
//   return (
//     <div className="flex items-center justify-center p-20 ">
//       <div className="w-1/2 ml-40">
//         <img
//           title="Crypto Video"
//           width="100%" 
//           height="100%" 
//           src={VideoCreditCard}
//           alt="Crypto Video"
//         />
//       </div>
//       <div className="w-1/2 mr-40 p-10"> 
//         <h2 className="text-5xl font-bold mb-6" style={{ fontFamily: 'Roboto-Black' }}>Most Effective Large-Scale <br /> BTC Mining</h2>
//         <p className="text-slate-500 text-xl mb-4" style={{ fontFamily: 'Roboto-Regular' }}>
//         Wattum is the world's leading cryptocurrency mining equipment distributor and complete mining solutions provider based out of the US. Wattum provides the full spectrum of hosted cryptocurrency mining services without any related complexities of managing mining hardware or software.
//         </p>
//         <p className="text-slate-500 text-xl mb-8" style={{ fontFamily: 'Roboto-Regular' }}>
//         Wattum provides turn-key macro-management in mining: software, firmware, mining pool, and mining farm management in North America and other countries all over the world. The company's products and services range from ASIC miners, mobile industrial mining containers, and power transformers.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default HomeCreditCard;



// import React from 'react';
// import VideoCreditCard from '../../assets/Videos/1.gif';


// const HomeCreditCard = () => {
//   return (
//     <div className="flex items-center justify-between p-20">
//       <div className="w-1/2">
//       <img
//           title="Crypto Video"
//           width="50%"
//           height="515"
//           src={VideoCreditCard}
//           alt="Crypto Video"
//         />
//       </div>
//       <div className="w-1/2 pl-4"> 
//         <h2 className="text-2xl font-bold mb-4">Buy Bitcoin with Credit Card</h2>
//         <p className="text-gray-700">
//         Buy and sell popular digital currencies lightning fast
//         and keep track of them in the one place.
//         </p>
//         <p className="text-gray-700">
//         Fastest access to the world of cryptocurrencies.
//         </p>
//         <button className="bg-blue-500 text-white px-4 py-2 mt-4 rounded">Get started</button>
//       </div>
//     </div>
//   );
// };

// export default HomeCreditCard;



// import React from 'react';
// import VideoCreditCard from "../../assets/Videos/1.gif";

// const HomeCreditCard = () => {
//   return (
//     <div className="flex justify-between p-8">
//       <div className="w-1/2">
//         <video
//           title="Crypto Video"
//           width="50%"
//           height="515"
//           src={VideoCreditCard}
//         />
//       </div>
//       <div className="w-1/2 pr-4">
//         <h2 className="text-2xl font-bold mb-4">Buy Bitcoin with Credit Card</h2>
//         <p className="text-gray-700">
//           Buy and sell popular digital currencies lightning fast and keep track of them in one place.
//           Fastest access to the world of cryptocurrencies.
//         </p>
//         <button className="bg-blue-500 text-white px-4 py-2 mt-4 rounded">Get started</button>
//       </div>

      
     
//     </div>
//   );
// };

// export default HomeCreditCard;
