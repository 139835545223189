import React from "react";
import "./GlobalCss.css"; 

const Footer = () => {
  return (
    <footer className="bg-white text-black px-2 ">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col sm:flex-row items-center justify-between mt-10 mb-10">
          <div className="text-center sm:text-left flex space-between ">
            <p className="text-sm text-slate-500" >© 2024-2030 Tokonite, Inc. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;



// import React from "react";

// const Footer = () => {
//   return (
//     <footer className="bg-white text-black px-2 md:px-24">
//       <div className="container mx-auto flex flex-col sm:flex-row justify-between items-start">
//         <div className="sm:flex">
//           <div className="mt-4 sm:w-1/4 sm:pr-4">
//           <span className="ml-2 text-xl text-black font-semibold mr-10">COINFALCON</span>     
//           </div>
//           <div className="mt-4 sm:w-1/4 sm:pr-4 ml-5">
//             <h4 className="text-xl text-blue-500 font-semibold" >
//               Company
//             </h4>
//             <ul className="mt-2 " >
//               <li><a href="/about" >About us</a></li>
//               <li><a href="/fees" >Fees</a></li>
//               <li><a href="/legal" >Legal & Privacy</a></li>
//               <li><a href="/referral" >Referral program</a></li>
//               <li><a href="/careers" >Careers</a></li>
//             </ul>
//           </div>
//           <div className="mt-4 sm:w-1/4 sm:pr-4 ">
//             <h4 className="text-xl text-blue-500 font-semibold" >
//               Learn
//             </h4>
//             <ul className="mt-2" >
//               <li><a href="/prices" >Prices</a></li>
//               <li><a href="/buy-bitcoin" >Buy Bitcoin</a></li>
//               <li><a href="/buy-bitcoin-cash" >Buy Bitcoin Cash</a></li>
//               <li><a href="/buy-ethereum" >Buy Ethereum</a></li>
//               <li><a href="/buy-litecoin" >Buy Litecoin</a></li>
//               <li><a href="/buy-xrp" >Buy XRP</a></li>
//               <li><a href="/buy-iota" >Buy IOTA</a></li>
//             </ul>
//           </div>
//           <div className="mt-4 sm:w-1/4 sm:pr-4">
//             <h4 className="text-xl text-blue-500 font-semibold" >
//               Support
//             </h4>
//             <ul className="mt-2" >
//               <li><a href="/help-center" >Help center</a></li>
//               <li><a href="/contact-us" >Contact us</a></li>
//               <li><a href="/api" >API</a></li>
//             </ul>
//           </div>
//           <div className="mt-4 sm:w-1/4 sm:pr-4">
//             <h4 className="text-xl text-blue-500 font-semibold" >
//               Social
//             </h4>
//             <ul className="mt-2" >
//               <li><a href="/blog" >Blog</a></li>
//               <li><a href="/facebook" >Facebook</a></li>
//               <li><a href="/twitter" >Twitter</a></li>
//               <li><a href="/reddit" >Reddit</a></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div className="mt-4 text-center">
//         <p className="text-sm" >© 2017-2023 CoinFalcon, Inc. All Rights Reserved</p>
//       </div>
//       <div className="mt-4 flex justify-center">
//         <img src="/images/mastercardvisa.png" alt="Accepted payment methods" className="h-12 w-auto" />
//       </div>
//       <div className="mt-4 flex justify-center">
//         <a href="#" className="mr-4">
//           <img src="/images/download-app-store.png" alt="Download on the App Store" className="h-10 w-auto" />
//         </a>
//         <a href="#">
//           <img src="/images/get-it-on-google-play.png" alt="Get it on Google Play" className="h-10 w-auto" />
//         </a>
//       </div>
//     </footer>
//   );
// };

// export default Footer;



// import React from "react";
// import Logo from "../assets/CDS 1 (1).png";
// // import './fontstyle.css';

// const Footer = () => {
//   return (
//     <footer className="bg-white text-black py-4 px-4">
//       <div className="container mx-auto flex flex-col sm:flex-row justify-between items-start">
//         <div className="sm:flex">
//           <div className="mt-4 sm:w-1/2 sm:pr-4">
//             <h4 className="text-xl text-orange-500 font-semibold" style={{ fontFamily: 'Mostin-Black' }}>
//               Quick Links
//             </h4>
//             <ul className="mt-2" style={{ fontFamily: 'MostinLight-jEJWO' }}>
//               <li>
//                 <a href="/" className="hover:text-orange-500">
//                   Home
//                 </a>
//               </li>
//               <li>
//                 <a href="/portfolio" className="hover:text-orange-500">
//                   Portfolio
//                 </a>
//               </li>
//               {/* <li>
//                 <a href="/pricing" className="hover:text-orange-500">
//                   Pricing
//                 </a>
//               </li> */}
//               <li>
//                 <a href="/about" className="hover:text-orange-500">
//                   About Us
//                 </a>
//               </li>
//               <li>
//                 <a href="/contact" className="hover:text-orange-500">
//                   Contact Us
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="mt-4 sm:w-1/2 sm:pl-4">
//             <h4 className="text-xl font-semibold text-orange-500" style={{ fontFamily: 'Mostin-Black' }}>
//               Contact Us
//             </h4>
//             <p className="mt-2" style={{ fontFamily: 'MostinLight-jEJWO' }}>Email: info@codesignservices.com</p>
//             <p style={{ fontFamily: 'MostinLight-jEJWO' }}>+1 859 203-3645</p>
//           </div>
//         </div>
//         <div className="flex flex-col mt-4">
//           <p className="text-xl font-semibold text-orange-500" style={{ fontFamily: 'Mostin-Black' }}>Mission</p>
//           <p className="mt-2" style={{ fontFamily: 'MostinLight-jEJWO' }}>Provide quality and excellence to our clients.</p>
//         </div>
//         <div className="mt-4 flex items-center">
//           {/* <img src={Logo} alt="Logo" className="h-26 w-32" /> */}
//           {/* <span className="ml-2 text-xl font-semibold">CODESIGN STUDIO</span> */}
//         </div>
//       </div>
//       <div className="mt-4 text-center">
//         <p className="text-sm" style={{ fontFamily: 'MostinLight-jEJWO' }}>Copyright © 2023, CODESIGN</p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;