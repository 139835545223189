import React from 'react'
import SettingHeading from '../components/Setting/settingHeading'
import SettingBar from '../components/Setting/settingBar'
import DashboardHeader from '../components/dashboardHeader'

const Setting = () => {
 return (
   <div className="w-full" style={{ background: "#f7fafc" }}>
     <DashboardHeader />
     <SettingHeading />
     <SettingBar />
   </div>
 )
}

export default Setting