import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/logos/CoinFalcon Logo.png";
import "./GlobalCss.css";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header
      className={`text-black transition-all duration-300 py-6 px-2 items-center ${
        isSticky ? "bg-white text-black sticky top-0 z-50" : ""
      }`}
      style={{
        fontFamily: "Roboto-Regular",
        background: isSticky ? "#ffffff" : "#f7fafc",
      }}
    >
      <nav className="flex justify-between items-center w-full">
        <a href="#homeBanner" className="flex items-center">
          <img src={Logo} alt="Logo" className="w-12 h-12" />
          <span
            className=" text-sm text-black font-extrabold "
            style={{ fontFamily: "Roboto-Medium" }}
          >TOKONITE
          </span>
        </a>

        <div className="md:hidden" onClick={toggleMenu}>
          {menuOpen ? (
            <FaTimes size={24} />
          ) : (
            <FaBars size={24} style={{ color: "#1AB6FF" }} />
          )}
        </div>

        <ul className="hidden md:flex md:space-x-8 md:items-center">
          <li>
            <a href="#homePackages" className="hover:text-blue-500">
              Packages
            </a>
          </li>
          <li>
            <a href="#homeCreditCard" className="hover:text-blue-500">
              About
            </a>
          </li>
          <li>
            <a href="#homeCustomerSlider" className="hover:text-blue-500">
              Testimonial
            </a>
          </li>
          <div className="ml-auto flex items-center space-x-4">
            <button className="mr-4">
              <a href="/login">Log in</a>
            </button>
            <button
              className="text-white text-md px-5 py-3 rounded-sm hover:bg-sky-400"
              style={{ background: "#1AB6FF", fontFamily: "Roboto-Regular" }}
            >
              <a href="/signup">Get Started</a>
            </button>
          </div>
        </ul>

        {menuOpen && (
          <ul className="md:hidden absolute top-full left-0 w-full bg-white text-center flex flex-col items-center space-y-4 py-4 z-50 shadow-lg">
            <li className="w-full border-b border-gray-200">
              <a
                href="#homePackages"
                className="block py-2 text-lg hover:text-blue-500"
                onClick={toggleMenu}
              >
                Packages
              </a>
            </li>
            <li className="w-full border-b border-gray-200">
              <a
                href="#homeCreditCard"
                className="block py-2 text-lg hover:text-blue-500"
                onClick={toggleMenu}
              >
                About
              </a>
            </li>
            <li className="w-full border-b border-gray-200">
              <a
                href="#homeCustomerSlider"
                className="block py-2 text-lg hover:text-blue-500"
                onClick={toggleMenu}
              >
                Testimonial
              </a>
            </li>
            <li className="w-full border-b border-gray-200">
              <a
                href="/login"
                className="block py-2 text-lg"
                onClick={toggleMenu}
              >
                Log in
              </a>
            </li>
            <li className="w-full">
              <a
                href="/signup"
                className="block text-white py-3 w-full text-center rounded-md hover:bg-blue-600"
                style={{ background: "#1AB6FF", fontFamily: "Roboto-Regular" }}
                onClick={toggleMenu}
              >
                Get Started
              </a>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
};

export default Header;

// import React, { useState, useEffect } from "react";
// import Logo from "../assets/logos/CoinFalcon Logo.png";
// import "./GlobalCss.css";

// const Header = () => {
//   const [isSticky, setIsSticky] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header
//       className={`text-black transition-all duration-300 py-6 px-2 items-center ${isSticky ? "bg-white text-black sticky top-0 z-50" : ""}`}
//       style={{ fontFamily: 'Roboto-Regular', background: isSticky ? "#ffffff" : "#f7fafc" }}
//     >
//       <nav className={`flex flex-wrap justify-center space-x-8 w-full`}>
//         <a href="#homeBanner" className="flex items-center">
//           <img src={Logo} alt="Logo" className="w-8 h-8" />
//           <span className="ml-2 text-sm text-black font-extrabold mr-10" style={{ fontFamily: 'Roboto-Medium' }}>TOKONITE</span>
//         </a>
//         <div className="relative group mt-3">
//           <a href="#homePackages" className="hover:text-blue-500">
//             Packages
//           </a>
//         </div>
//         <div className="relative group mt-3">
//           <a href="#homeCreditCard" className="hover:text-blue-500">
//             About
//           </a>
//         </div>
//         <div className="relative group mt-3">
//           <a href="#homeCustomerSlider" className="hover:text-blue-500">
//             Testimonial
//           </a>
//         </div>
//         <div className="ml-auto flex items-center space-x-4">
//           <button className="mr-4"><a href="/login">Log in</a></button>
//           <button className="text-white text-md px-5 py-3 rounded-sm hover:bg-sky-400" style={{ background: '#1AB6FF', fontFamily: 'Roboto-Regular' }}>
//             <a href="/signup">Get Started</a>
//           </button>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars, faAngleDown, faLineChart, faThunder, faFileContract, faMarker } from "@fortawesome/free-solid-svg-icons";
// import Logo from "../assets/logos/CoinFalcon Logo.png";
// import MobileProduct from "../assets/productMobile.png";
// import Google from "../assets/others/google play.png";
// import Iphone from "../assets/others/download.png";
// import Lighting from "../assets/others/lighting.png";
// import Arrow from "../assets/others/arrow.png";
// import "./GlobalCss.css";

// const Header = () => {
//   const [isSticky, setIsSticky] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header
//       className={`text-black transition-all duration-300 py-6 px-2 items-center ${isSticky ? "bg-white text-black sticky top-0 z-50" : ""}`}
//       style={{ fontFamily: 'Roboto-Regular', background: isSticky ? "#ffffff" : "#f7fafc" }}
//     >
//       <nav className={`flex flex-wrap justify-center space-x-8 w-full`}>
//         <a href="/" className="flex items-center">
//           <img src={Logo} alt="Logo" className="w-8 h-8" />
//           <span className="ml-2 text-sm text-black font-extrabold mr-10" style={{ fontFamily: 'Roboto-Medium' }}>COINFALCON</span>
//         </a>
//         <div className="relative group mt-3">
//             <a href="/homePackages" className="hover:text-blue-500">
//               Packages
//             </a>
//         </div>
//         <div className="relative group mt-3">
//           <a href="/homeCreditCard" className="hover:text-blue-500">
//             About
//           </a>
//         </div>
//         <div className="relative group mt-3">
//           <a href="/homeCustomerSlider" className="hover:text-blue-500">
//             Testimonial
//           </a>
//         </div>
//         <div className="ml-auto flex items-center space-x-4">
//           <button className="mr-4"><a href="/login">Log in</a></button>
//           <button className="text-white text-md px-5 py-3 rounded-sm hover:bg-sky-400" style={{background:'#1AB6FF', fontFamily: 'Roboto-Regular'}}>
//             <a href="/signup">Get Started</a>
//           </button>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars, faAngleDown, faLineChart, faThunder, faFileContract, faMarker } from "@fortawesome/free-solid-svg-icons";
// import Logo from "../assets/logos/CoinFalcon Logo.png";
// import MobileProduct from "../assets/productMobile.png";
// import Google from "../assets/others/google play.png";
// import Iphone from "../assets/others/download.png";
// import Lighting from "../assets/others/lighting.png";
// import Arrow from "../assets/others/arrow.png";
// import "./GlobalCss.css";

// const Header = () => {
//   const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);
//   const [isCompanyDropdownOpen, setCompanyDropdownOpen] = useState(false);
//   const [isSticky, setIsSticky] = useState(false);

//   const toggleProductsDropdown = () => {
//     setProductDropdownOpen(!isProductDropdownOpen);
//   };

//   const toggleCompanyDropdown = () => {
//     setCompanyDropdownOpen(!isCompanyDropdownOpen);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header
//       className={`text-black transition-all duration-300 py-6 px-2   items-center ${isSticky ? "bg-white text-black sticky top-0 z-50" : ""}`}
//       style={{ fontFamily: 'Roboto-Regular', background: isSticky ? "#ffffff" : "#f7fafc" }}
//     >
//       <nav className={`flex justify-center space-x-8 w-full  `}>
//         <a href="/" className="flex items-center">
//           <img src={Logo} alt="MasterCard" className="w-8 h-8 " />
//           <span className="ml-2 text-sm text-black font-extrabold mr-10" style={{ fontFamily: 'Roboto-Medium' }}>COINFALCON</span>
//         </a>
//           <div className="relative group">
//             <div
//                 onClick={toggleProductsDropdown}
//                 className="hover:text-sky-500 cursor-pointer mt-3"
//               >
//                 Products
//                 <FontAwesomeIcon
//                   icon={faAngleDown}
//                   className={`ml-2 h-4 w-4 transition-transform transform ${
//                     isProductDropdownOpen ? "rotate-180" : ""
//                   }`}
//                 />
//               </div>
//               {isProductDropdownOpen && (
//                 <div className="absolute bg-white mt-0 border rounded-md border-gray-300 text-black w-screen flex pt-4 pr-4 pl-4">
//                   <div className="md:order-1 mt-5">
//                   <h1 className="text-md text-slate-500 mb-4" style={{ fontFamily: 'Roboto-Regular' }}>Our products</h1>
//                     <div className=" mt-5 flex items-center">
//                       <div>
//                         <img src={Lighting} alt="Visa" className="w-12 h-10 mr-2" />
//                       </div>
//                       <div>
//                         <h1 className="text-lg font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>Instant</h1>
//                         <p className="text-sm text-slate-500 mb-2" style={{ fontFamily: 'Roboto-Regular' }}>For everyone. Easily buy & sell cryptocurrencies.</p>
//                       </div>
//                     </div>
//                     <div className="mt-4 flex items-center">
//                       <div>
//                         <img src={Arrow} alt="Visa" className="w-12 h-10 mr-2" />
//                       </div>
//                       <div>
//                         <h1 className="text-lg font-bold mb-2" style={{ fontFamily: 'Roboto-Black' }}>Advanced</h1>
//                         <p className="text-sm text-slate-500 mb-2" style={{ fontFamily: 'Roboto-Regular' }}>Advanced cryptocurrency trading.</p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="md:order-2 ml-8 mt-5">
//                     <h1 className="text-3xl font-extrabold mb-4 ml-1" style={{ fontFamily: 'Roboto-Black' }}>Try out mobile app</h1>
//                     <div className="mb-6 ml-1">
//                       <h1 className="text-md text-slate-500 mb-2" style={{ fontFamily: 'Roboto-Regular' }}>Trading is a click away no matter where you are.</h1>
//                       <p className="text-md text-slate-500 mb-2" style={{ fontFamily: 'Roboto-Regular' }}>Available on iOS and Android.</p>
//                     </div>
//                     <div className="flex items-center  ">
//                       <a href="link-to-app-store" >
//                         <img src={Iphone}  className="w-21 h-10" />
//                       </a>
//                       <a href="link-to-google-play" >
//                         <img src={Google}  className="w-22 h-14 " />
//                       </a>
//                     </div>
//                   </div>
//                   <div className="md:order-3 ml-8">
//                     <img src={MobileProduct} className="h-90 w-90 mx-auto " alt="Mobile Product" />
//                   </div>
//                 </div>
//               )}
//           </div>
//           <div className="relative group mt-3">
//             <a href="/prices" className="hover:text-blue-500">
//               Prices
//             </a>
//           </div>
//           <div className="relative group mt-3">
//             <div
//               onClick={toggleCompanyDropdown}
//               className="hover:text-sky-500 cursor-pointer"
//             >
//               Company
//               <FontAwesomeIcon
//                 icon={faAngleDown}
//                 className={`ml-2 h-4 w-4 transition-transform transform ${
//                   isCompanyDropdownOpen ? "rotate-180" : ""
//                 }`}
//               />
//             </div>
//             {isCompanyDropdownOpen && (
//             <div className="absolute left-0 mt-2 bg-white border rounded-md border-gray-300 text-black w-40 flex flex-col">
//             <a href="/about" className="flex justify-center py-2 hover:bg-gray-100">About us</a>
//             <a href="/blog" className="flex justify-center py-2 hover:bg-gray-100">Blog</a>
//             <a href="/support" className="flex justify-center py-2 hover:bg-gray-100">Support</a>
//           </div>
//             )}
//           </div>
//         <div className="ml-auto pl-80  flex items-center ">
//           <button className="mr-4"><a href="/login" >Log in</a></button>
//           <button className=" text-white text-md px-5 py-3 rounded-sm hover:bg-sky-400" style={{background:'#1AB6FF', fontFamily: 'Roboto-Regular'}} >
//           <a href="/signup" >Get Started</a>
//           </button>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;
