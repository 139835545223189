import React from 'react';
import Image1 from "../../assets/others/about-1.jpg";
import Image2 from "../../assets/others/about-2.jpg";
import "../GlobalCss.css"; 

const AboutInfo= () => {
  return (
    <div className=" flex flex-col items-center justify-center min-h-screen px-4 lg:px-96 md:px-48 mb-16">
      <p className="text-5xl mb-16" style={{ fontFamily: 'Roboto-Black' }}>
        We're based in <span className="font-bold">🇬🇧 United Kingdom</span>.
      </p>
      <div className="flex">
        <img
          className="w-3/2 h-auto mr-2"
          src={Image1}
          alt="Image 1"
        />
        <img
          className="w-7/2 h-auto ml-4"
          src={Image2}
          alt="Image 2"
        />
      </div>
    </div>
  );
};

export default AboutInfo;
