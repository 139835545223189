import React from "react";
import HomeBanner from "../components/Home/homeBanner";
import HomeVideo from "../components/Home/homeVideo";
import HomeCreditCard from "../components/Home/homeCreditCard";
import HomePortfolioTracker from "../components/Home/homePortfolioTracker";
import HomeTrade from "../components/Home/homeTrade";
import HomeAccessible from "../components/Home/homeAccessible";
import HomeEmail from "../components/Home/homeEmail";
import HomeBitcoinMining from "../components/Home/homeBitcoinMining";
import HomeHashing from "../components/Home/homeHashing";
import HomeSlider from "../components/Home/homeSlider";
import HomeCustomerSlider from "../components/Home/homeCustomerSlider";
import HomeStartMining from "../components/Home/homeStartMining";
import HomeBannerDemo from "../components/Home/homeBannerDemo";
import HomeBannerTrading from "../components/Home/homeBannerTrading";
import HomeBannerSupply from "../components/Home/homeBannerSupply";
import HomePackages from "../components/Home/homePackages";
import Header from "../../src/components/header"; 
import Footer from "../components/footer";

const Home = () => {
  return (
    <div className="w-full" style={{ background: "#f7fafc" }}>
      <Header />

      <section id="homeBanner">
        <HomeBanner />
      </section>

      <section id="homeCreditCard">
        <HomeCreditCard />
      </section>

      <section id="homePortfolioTracker">
        <HomePortfolioTracker />
      </section>

      <section id="homePackages">
        <HomePackages />
      </section>

      <section id="homeAccessible">
        <HomeAccessible />
      </section>

      <section id="homeSlider">
        <HomeSlider />
      </section>

      <section id="homeBitcoinMining">
        <HomeBitcoinMining />
      </section>

      <section id="homeBannerDemo">
        <HomeBannerDemo />
      </section>

      <section id="homeCustomerSlider">
        <HomeCustomerSlider />
      </section>

      <section id="homeHashing">
        <HomeHashing />
      </section>

      <section id="homeBannerTrading">
        <HomeBannerTrading />
      </section>

      <section id="homeStartMining">
        <HomeStartMining />
      </section>

      <section id="homeBannerSupply">
        <HomeBannerSupply />
      </section>
      <Footer />
    </div>
  );
};

export default Home;




// import React from "react";
// import HomeBanner from "../components/Home/homeBanner";
// import HomeVideo from "../components/Home/homeVideo";
// import HomeCreditCard from "../components/Home/homeCreditCard";
// import HomePortfolioTracker from "../components/Home/homePortfolioTracker";
// import HomeTrade from "../components/Home/homeTrade";
// import HomeAccessible from "../components/Home/homeAccessible";
// import HomeEmail from "../components/Home/homeEmail";
// import HomeBitcoinMining from "../components/Home/homeBitcoinMining";
// import HomeHashing from "../components/Home/homeHashing";
// import HomeSlider from "../components/Home/homeSlider";
// import HomeCustomerSlider from "../components/Home/homeCustomerSlider";
// import HomeStartMining from "../components/Home/homeStartMining";
// import HomeBannerDemo from "../components/Home/homeBannerDemo";
// import HomeBannerTrading from "../components/Home/homeBannerTrading";
// import HomeBannerSupply from "../components/Home/homeBannerSupply";
// import HomePackages from "../components/Home/homePackages";


// const Home = () => {
//   return (
//     <div className="w-full " style={{background: "#f7fafc"}}>
//       <HomeBanner />
//       {/* <HomeVideo /> */}
//       <HomeCreditCard /> 
//       <HomePortfolioTracker />
//       <HomePackages />
//       <HomeAccessible />
//       <HomeSlider />
//       <HomeBitcoinMining />
//       <HomeBannerDemo />
//       <HomeCustomerSlider />
//       <HomeHashing />
//       <HomeBannerTrading />
//       <HomeStartMining />
//       <HomeBannerSupply />

      
      
//       {/* <HomeTrade />
//       <HomeEmail /> */}

//     </div>
//   );
// };

// export default Home;