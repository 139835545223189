import React from "react";
import LoginForm from "../components/Login/loginForm";
import LoginHeader from "../components/Login/loginHeader";

const Login = () => {
  return (
    <div className="w-full bg-white" >
        <LoginHeader />
        <LoginForm />
      
    </div>
  );
};

export default Login;