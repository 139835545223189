import React, { useState } from "react";
import axios from "axios";
import "../GlobalCss.css";

const SignupForm = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordToggle = () => setShowPassword(!showPassword);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.password !== formData.password_confirmation) {
      setError("Passwords do not match.");
      setResponseMessage(null);
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/auth/register`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Registration successful!", response.data);
      setResponseMessage("Registration successful!");
      setError(null);
      if (response.status === 200) {
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Registration failed!", error);
      if (error.response && error.response.data) {
        setError(error.response.data.message || "Server validation errors.");
      } else {
        setError("Registration failed due to an unexpected error.");
      }
      setResponseMessage(null);
    }
  };

  return (
    <div className=" flex flex-col items-center justify-center p-2">
      <div className="max-w-md w-full">
        <h2
          className="text-3xl font-bold mb-8 text-center"
          style={{ fontFamily: "Roboto-Black" }}
        >
          Create your account
        </h2>
        <form className="space-y-4 p-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="name"
              className="block text-sm mb-1 font-medium"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              placeholder="Name"
              style={{ fontFamily: "Roboto-Regular" }}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm mb-1 font-medium"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              Email address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              placeholder="Email"
              style={{ fontFamily: "Roboto-Regular" }}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm mb-1 font-medium"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              placeholder="Password"
              style={{ fontFamily: "Roboto-Regular" }}
            />
          </div>
          <div>
            <label
              htmlFor="password_confirmation"
              className="block text-sm mb-1 font-medium"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              Confirm Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password_confirmation"
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleInputChange}
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              placeholder="Confirm Password"
              style={{ fontFamily: "Roboto-Regular" }}
            />
          </div>
          <div className="flex items-center justify-between mb-4 w-full whitespace-nowrap">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={handleShowPasswordToggle}
                className="mr-2"
              />
              <label
                htmlFor="showPassword"
                className="text-sm"
                style={{ fontFamily: "Roboto-Regular" }}
              >
                Show Password
              </label>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full text-white p-3 text-lg rounded-md"
              style={{ fontFamily: "Roboto-Medium", background: "#1AB6FF" }}
            >
              Create account
            </button>
          </div>

          {responseMessage && (
            <p
              className="text-blue-500 text-center mt-4"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {responseMessage}
            </p>
          )}
          {error && (
            <p
              className="text-red-500 text-center mt-4"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {error}
            </p>
          )}
        </form>

        <p
          className="mt-4 text-center text-gray-600"
          style={{ fontFamily: "Roboto-Regular" }}
        >
          Already have an account?{" "}
          <a href="/login" className="text-blue-500">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignupForm;

// import React, { useState } from 'react';
// import axios from "axios";
// import "../GlobalCss.css";

// const SignupForm = () => {
//   const apiUrl = process.env.REACT_APP_BASE_URL;
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     password_confirmation: "",
//   });
//   const [error, setError] = useState(null);
//   const [responseMessage, setResponseMessage] = useState(null);

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (formData.password !== formData.password_confirmation) {
//       setError("Passwords do not match.");
//       setResponseMessage(null);
//       return;
//     }

//     try {

//       const response = await axios.post(
//         `${apiUrl}/auth/register`,
//         formData,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       console.log("Registration successful!", response.data);
//       setResponseMessage("Registration successful!");
//       setError(null);
//       if (response.status === 200) {
//         window.location.href = "/";
//       }

//     } catch (error) {
//       console.error("Registration failed!", error);
//       if (error.response && error.response.data) {
//         setError(error.response.data.message || "Server validation errors.");
//       } else {
//         setError("Registration failed due to an unexpected error.");
//       }
//       setResponseMessage(null);
//     }
//   };

//   return (
//     <div className="min-h-screen flex flex-col items-center justify-center">
//       <div className="max-w-md w-full">
//         <h2 className="text-3xl font-bold mb-16 text-center" style={{ fontFamily: 'Roboto-Black' }}>Create your account</h2>
//         <form className="space-y-4" onSubmit={handleSubmit}>
//           <div>
//             <label htmlFor="name" className="block text-md mb-2 font-medium" style={{ fontFamily: 'Roboto-Regular' }}>
//               Name
//             </label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               value={formData.name}
//               onChange={handleInputChange}
//               className="mt-1 p-4 text-lg w-full border rounded-md"
//               placeholder="Name"
//               style={{ fontFamily: 'Roboto-Regular' }}
//             />
//           </div>
//           <div>
//             <label htmlFor="email" className="block text-md mb-2 font-medium" style={{ fontFamily: 'Roboto-Regular' }}>
//               Email address
//             </label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="mt-1 p-4 text-lg w-full border rounded-md"
//               placeholder="Email"
//               style={{ fontFamily: 'Roboto-Regular' }}
//             />
//           </div>
//           <div>
//             <label htmlFor="password" className="block text-md mb-2 font-medium" style={{ fontFamily: 'Roboto-Regular' }}>
//               Password
//             </label>
//             <input
//               type="password"
//               id="password"
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//               className="mt-1 p-4 text-lg w-full border rounded-md"
//               placeholder="Password"
//               style={{ fontFamily: 'Roboto-Regular' }}
//             />
//           </div>
//           <div>
//             <label htmlFor="confirm_password" className="block text-md mb-2 font-medium" style={{ fontFamily: 'Roboto-Regular' }}>
//               Confirm Password
//             </label>
//             <input
//               type="password"
//               id="password_confirmation"
//               name="password_confirmation"
//               value={formData.password_confirmation}
//               onChange={handleInputChange}
//               className="mt-1 p-4 text-lg w-full border rounded-md"
//               placeholder="Confirm Password"
//               style={{ fontFamily: 'Roboto-Regular' }}
//             />
//           </div>
//           <div>
//             <button
//               type="submit"
//               className="w-full text-white p-4 text-lg rounded-md"
//               style={{ fontFamily: 'Roboto-Medium', background: '#1AB6FF' }}
//             >
//               Create account
//             </button>
//           </div>

//           {responseMessage && (
//             <p className="text-blue-500 text-center mt-4" style={{ fontFamily: 'Roboto-Regular' }}>
//               {responseMessage}
//             </p>
//           )}
//           {error && (
//             <p className="text-red-500 text-center mt-4" style={{ fontFamily: 'Roboto-Regular' }}>
//               {error}
//             </p>
//           )}
//         </form>

//         <p className="mt-4 text-center text-gray-600" style={{ fontFamily: 'Roboto-Regular' }}>
//           Already have an account?{' '}
//           <a href="/login" className="text-blue-500">
//             Log in
//           </a>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default SignupForm;

// import React from 'react';

// const SignupForm = () => {
//   return (
//     <div className="min-h-screen flex items-center justify-center">
//       <div className="max-w-md w-full">
//         <h2 className="text-3xl font-bold mb-6 items-center">Create your account</h2>
//         <form className="space-y-4">
//           <div>
//             <label htmlFor="email" className="block text-md mb-2 font-medium text-gray-600">
//               Email address
//             </label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               className="mt-1 p-4 text-lg w-full border rounded-md"
//               placeholder="Email"
//             />
//           </div>
//           <div>
//             <label htmlFor="password" className="block text-md mb-2 font-medium text-gray-600">
//               Password
//             </label>
//             <input
//               type="password"
//               id="password"
//               name="password"
//               className="mt-1 p-4 text-lg w-full border rounded-md"
//               placeholder="Password"
//             />
//           </div>
//           <div>
//             <label htmlFor="country" className="block text-md mb-2 font-medium text-gray-600">
//               Country of residence
//             </label>
//             <select
//               id="country"
//               name="country"
//               className="mt-1 p-4 text-lg w-full border rounded-md"
//             >
//               <option value="austria">Austria</option>
//               <option value="belgium">Belgium</option>
//               <option value="austria">Bulgaria</option>
//               <option value="belgium">Cyprus</option>
//               <option value="austria">Czech Republic</option>
//               <option value="belgium">Denmark</option>
//               <option value="austria">Estonia</option>
//               <option value="belgium">Finland</option>
//               <option value="austria">France</option>
//               <option value="belgium">Hungary</option>
//               <option value="austria">Iceland</option>
//               <option value="belgium">Italy</option>
//               <option value="austria">Latvia</option>
//               <option value="belgium">Liechtenstein</option>
//               <option value="belgium">Luxembourg</option>
//               <option value="austria">Netherlands</option>
//               <option value="belgium">Norway</option>
//               <option value="austria">Portugal</option>
//               <option value="belgium">Romania</option>
//               <option value="austria">Slovakia</option>
//               <option value="belgium">Slovenia</option>
//               <option value="austria">Spain</option>
//               <option value="belgium">Sweden</option>
//               <option value="belgium">Switzerland</option>
//             </select>
//           </div>
//           <div>
//             <button
//               type="submit"
//               className="w-full bg-blue-500 text-white p-4 text-lg rounded-md"
//             >
//               Create account
//             </button>
//           </div>
//         </form>
//         <p className="mt-4">
//           Already have an account?{' '}
//           <a href="#" className="text-blue-500">
//             Log in
//           </a>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default SignupForm;
