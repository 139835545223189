import React from 'react';
import HomePhone from "../../assets/others/phone screen.png"
import Google from "../../assets/others/google play.png";
import Iphone from "../../assets/others/download.png";
import "../GlobalCss.css";

const HomeTrade = () => {
  return (
    <div className="flex p-5 mt-40">
      <div className="w-1/2 p-8 ml-60 " >
        <img src={HomePhone}  alt="Trading App"/>
      </div>
      <div className="w-1/2 mr-60 mt-40">
        <h2 className="text-6xl font-bold mb-8" style={{ fontFamily: 'Roboto-Black' }}>Trade on the go</h2>
        <p className="mb-4 text-slate-500 text-xl" style={{ fontFamily: 'Roboto-Regular' }}>
          No matter where you are, trading is just one click away.
        </p>
        <p className="mb-4 text-slate-500 text-xl" style={{ fontFamily: 'Roboto-Regular' }}>
          Stay updated on your investment anytime and stay informed on the latest
          price of Bitcoin, Ethereum, and other trending digital assets.
        </p>
        <p className="mb-4 text-slate-500 text-xl" style={{ fontFamily: 'Roboto-Regular' }}>Available on iOS and Android.</p>

        
        <div className="flex items-center justify-center md:justify-start mt-20">
          <a
            href="link-to-app-store"
            
          >
           <img src={Iphone}  className="w-41 h-16" />
          </a>
          <a
            href="link-to-google-play"
            
          >
            <img src={Google}  className="w-42 h-20 " />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeTrade;

