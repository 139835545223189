import React from 'react'
import StatisticsTable2 from '../components/Statistics/satisticsTable2'
import DashboardHeader from '../components/dashboardHeader'
import StatisticsHeading from '../components/Statistics/satisticsHeading'
import StatisticsTable from '../components/Statistics/satisticsTable1'
import StatisticsBox from '../components/Statistics/satisticsBox'

const Statistics = () => {
 return (
   <div className="w-full " style={{background: "#f7fafc"}}>
     <DashboardHeader />
     <StatisticsHeading />
     <StatisticsBox />
     {/* <div className='flex flex-row'> */}
     <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 p-4">
     <StatisticsTable />
     <StatisticsTable2 />
     </div>
   </div>
 )
}

export default Statistics