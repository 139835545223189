import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import "../GlobalCss.css";

const TransfersTable = () => {
  const [txData, setTxData] = useState([]); 
  const [nextPageUrl, setNextPageUrl] = useState(null); 
  const [prevPageUrl, setPrevPageUrl] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1); 
  const token = localStorage.getItem('accessToken'); 
  const apiUrl = process.env.REACT_APP_BASE_URL;
  
  const fetchTransfers = async (url, page) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      console.log("Full API Response:", response.data);
      
      setTxData(response.data.data); 
      setNextPageUrl(response.data.next_page_url ? response.data.next_page_url.replace(/^(http[s]?:\/\/)?[^\/]+\/api/, `${apiUrl}`) : null); 
      setPrevPageUrl(response.data.prev_page_url ? response.data.prev_page_url.replace(/^(http[s]?:\/\/)?[^\/]+\/api/, `${apiUrl}`) : null);

      setCurrentPage(page); 

    } catch (error) {
      console.error("Error fetching data", error);
      setTxData([]); 
    }
  };

  useEffect(() => {
    fetchTransfers(`${apiUrl}/user/transfers?page=1`, 1); 
  }, [apiUrl]); 

  const handleNextPage = () => {
    if (nextPageUrl) {
      const nextPageNumber = currentPage + 1;
      fetchTransfers(nextPageUrl, nextPageNumber); 
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      const prevPageNumber = currentPage - 1;
      fetchTransfers(prevPageUrl, prevPageNumber); 
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-6 p-20">
      <div className="overflow-x-auto w-full">
        <table className="bg-white border shadow-md min-w-full">
          <thead>
            <tr>
              <th colSpan="5" className="p-4 lg:p-8">
                <div className="flex justify-between">
                  <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
                    Transfers
                  </h1>
                </div>
              </th>
            </tr>
            <tr>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>ID</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Sender Email</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Receiver Email</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Amount(BTC)</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Transaction Time</th>
            </tr>
          </thead>
          <tbody>
            {txData.length > 0 ? (
              txData.map((tx, index) => (
                <tr key={tx.id} className="hover:bg-gray-100 transition duration-200">
                  <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>
                    {(currentPage - 1) * 10 + index + 1} 
                  </td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.sender_email}</td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.receiver_email}</td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.amount}</td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{(tx.created_at).toLocaleString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="p-4 text-center">No transactions found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between w-full px-4 mt-4">
        <button 
          onClick={handlePrevPage} 
          disabled={!prevPageUrl}
          className={`mb-2 px-4 py-2 border rounded ${!prevPageUrl ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
        >
          Previous
        </button>
        <button 
          onClick={handleNextPage} 
          disabled={!nextPageUrl}
          className={`px-4 py-2 border rounded ${!nextPageUrl ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
        >
          Next
        </button>
      </div>
      <p className="mt-2">Current Page: {currentPage}</p>
    </div>
  );
};

export default TransfersTable;




