import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import "../GlobalCss.css";

const StatisticsTable2 = () => {
  const [txData, setTxData] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 
  const token = localStorage.getItem('accessToken'); 
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/wallet/address/txs/deposit`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        console.log("API Response:", response.data); 
  
        if (Array.isArray(response.data)) {
          setTxData(response.data);
        } else {
          console.error("Unexpected response format");
          setTxData([]);
        }
      } catch (error) {
        console.error("Error fetching data", error);
        setTxData([]); 
      }
    };
  
    fetchData();
  }, [token, apiUrl]);
  
  const totalPages = Math.ceil(txData.length / itemsPerPage);

  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = txData.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  return (
    <div className="flex flex-col items-center pt-10 pb-10 w-full lg:w-1/2 px-4 lg:px-0">
      <div className="overflow-x-auto w-full">
        <table className="bg-white border shadow-md min-w-full">
          <thead>
            <tr>
              <th colSpan="3" className="p-4 lg:p-8">
                <div className="flex justify-between">
                  <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
                    Deposit History
                  </h1>
                </div>
              </th>
            </tr>
            <tr>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>TXID</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Amount</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Confirmed</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.length > 0 ? (
              currentTransactions.map((tx, index) => (
                <tr key={index} className="hover:bg-gray-100 transition duration-200">
                  <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>
                    <a 
                      href={`https://blockstream.info/tx/${tx.txid}`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      {`${tx.txid.slice(0, 15)}...${tx.txid.slice(-15)}`}
                    </a>
                  </td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.amount}</td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.confirmed ? "Yes" : "No"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="p-4 text-center">No transactions found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between w-full px-4 mt-4">
        <button 
          onClick={handlePrevPage} 
          disabled={currentPage === 1}
          className={`mb-2 px-4 py-2 border rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
        >
          Previous
        </button>
        <span className="self-center">{`Page ${currentPage} of ${totalPages}`}</span>
        <button 
          onClick={handleNextPage} 
          disabled={currentPage === totalPages}
          className={`px-4 py-2 border rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StatisticsTable2;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios'; 
// import "../GlobalCss.css";

// const StatisticsTable = () => {
//   const [txData, setTxData] = useState([]); 
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10; 
//   const token = localStorage.getItem('accessToken'); 
//   const apiUrl = process.env.REACT_APP_BASE_URL;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/user/wallet/address/txs/deposit`, {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         });
  
//         console.log("API Response:", response.data); 
  
//         if (Array.isArray(response.data)) {
//           setTxData(response.data);
//         } else {
//           console.error("Unexpected response format");
//           setTxData([]);
//         }
//       } catch (error) {
//         console.error("Error fetching data", error);
//         setTxData([]); 
//       }
//     };
  
//     fetchData();
//   }, [token, apiUrl]);
  
//   const totalPages = Math.ceil(txData.length / itemsPerPage);
  
//   const currentItems = txData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };
  
//   return (
//     <div className="flex flex-col items-center pt-10 pb-10 w-full lg:w-1/2 px-4 lg:px-0">
//       <div className="overflow-x-auto w-full">
//         <table className="bg-white border shadow-md min-w-full">
//           <thead>
//             <tr>
//               <th colSpan="3" className="p-4 lg:p-8">
//                 <div className="flex justify-between">
//                   <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
//                     Deposit History
//                   </h1>
//                 </div>
//               </th>
//             </tr>
//             <tr>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>TXID</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Amount</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Confirmed</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentItems.length > 0 ? (
//               currentItems.map((tx, index) => (
//                 <tr key={index} className="hover:bg-gray-100 transition duration-200">
//                   <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>
//                     <a 
//                       href={`https://blockstream.info/tx/${tx.txid}`} 
//                       target="_blank" 
//                       rel="noopener noreferrer" 
//                       className="text-blue-500 underline hover:text-blue-700"
//                     >
//                       {`${tx.txid.slice(0, 15)}...${tx.txid.slice(-15)}`}
//                     </a>
//                   </td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.amount}</td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.confirmed ? "Yes" : "No"}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="3" className="p-4 text-center">No transactions found</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//       <div className="flex justify-between w-full px-4 mt-4">
//         <button 
//           onClick={handlePrevPage} 
//           disabled={currentPage === 1}
//           className={`mb-2 px-4 py-2 border rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
//         >
//           Previous
//         </button>
//         <button 
//           onClick={handleNextPage} 
//           disabled={currentPage === totalPages}
//           className={`px-4 py-2 border rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default StatisticsTable;




// import React, { useEffect, useState } from 'react';
// import axios from 'axios'; 
// import "../GlobalCss.css";

// const StatisticsTable = () => {
//   const [txData, setTxData] = useState([]); 
//   const token = localStorage.getItem('accessToken'); 
//   const apiUrl = process.env.REACT_APP_BASE_URL;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/user/wallet/address/txs/deposit`, {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         });
  
//         console.log("API Response:", response.data); 
  
//         if (Array.isArray(response.data)) {
//           setTxData(response.data);
//         } else {
//           console.error("Unexpected response format");
//           setTxData([]);
//         }
//       } catch (error) {
//         console.error("Error fetching data", error);
//         setTxData([]); 
//       }
//     };
  
//     fetchData();
//   }, [token, apiUrl]);
  
//   return (
//     <div className="flex flex-col items-center pt-10 pb-10 w-full lg:w-1/2 px-4 lg:px-0">
//       <div className="overflow-x-auto w-full">
//         <table className="bg-white border shadow-md min-w-full">
//           <thead>
//             <tr>
//               <th colSpan="3" className="p-4 lg:p-8">
//                 <div className="flex justify-between">
//                   <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
//                     Deposit History
//                   </h1>
//                 </div>
//               </th>
//             </tr>
//             <tr>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>TXID</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Amount</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Confirmed</th>
//             </tr>
//           </thead>
//           <tbody>
//             {txData.length > 0 ? (
//               txData.map((tx, index) => (
//                 <tr key={index} className="hover:bg-gray-100 transition duration-200">
//                   <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>
//                      <a 
//                       href={`https://blockstream.info/tx/${tx.txid}`} 
//                       target="_blank" 
//                       rel="noopener noreferrer" 
//                       className="text-blue-500 underline hover:text-blue-700"
//                     >
//                          {`${tx.txid.slice(0, 15)}...${tx.txid.slice(-15)}`}
//                     </a>
//                   </td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.amount}</td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.confirmed ? "Yes" : "No"}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="3" className="p-4 text-center">No transactions found</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default StatisticsTable;



