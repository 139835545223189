import React from 'react';
import "../GlobalCss.css";
import icon1 from "../../assets/others/icon1.png";
import icon2 from "../../assets/others/icon2.png";
import icon3 from "../../assets/others/icon3.png";
import icon4 from "../../assets/others/icon4.png";
import icon5 from "../../assets/others/icon5.png";

const HomeAccessible = () => {
  const cryptoData = [
    {
      image: icon4,
      title: 'Instant trading',
      description: 'Solid and pioneering 10 years on the BTC cloud mining market',
    },
    {
      image: icon2,
      title: 'Low & transparent fees',
      description: 'Sold out 135 000 PH/s hashrate capacities',
    },
    {
      image: icon3,
      title: 'European based',
      description: '1000+ BTC mined & withdrawn by our clients',
    },
    {
      image: icon1,
      description: 'Low entry point — investment starts from 1 TH/s',
    },
    {
      image: icon5,
      title: 'Secure storage',
      description: '200 000+ users registered with Tokonite',
    },
  ];

  return (
    <div className="flex flex-col items-center justify-end p-4 md:p-8">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 md:mb-8" style={{ fontFamily: 'Roboto-Black' }}>
        Record High Numbers
      </h1>
      <div className="container mx-auto mt-4 md:mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 px-4 md:px-20 lg:px-40">
          {cryptoData.map((item, index) => (
            <div key={index} className="bg-white flex flex-col items-center justify-center p-4 md:p-6 shadow-lg rounded-md">
              <img src={item.image} alt={item.title} className="w-16 h-16 mb-4" />
              <p className="text-gray-600 text-center text-sm md:text-md" style={{ fontFamily: 'Roboto-Regular' }}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeAccessible;



// import React from 'react';
// import "../GlobalCss.css";
// import icon1 from "../../assets/others/icon1.png";
// import icon2 from "../../assets/others/icon2.png";
// import icon3 from "../../assets/others/icon3.png";
// import icon4 from "../../assets/others/icon4.png";
// import icon5 from "../../assets/others/icon5.png";

// const HomeAccessible = () => {
//   const cryptoData = [
//     {
//       image: icon4,
//       title: 'Instant trading',
//       description: 'Solid and pioneering 10 years on the BTC cloud mining market',
//     },
//     {
//       image: icon2,
//       title: 'Low & transparent fees',
//       description: 'Sold out 135 000 PH/s hashrate capacities',
//     },
//     {
//       image: icon3,
//       title: 'European based',
//       description: '1000+ BTC mined & withdrawn by our clients',
//     },
//     {
//       image: icon1,
//       description: 'Low entry point — investment starts from 1 TH/s',
//     },
//     {
//       image: icon5,
//       title: 'Secure storage',
//       description: '200 000+ users registered with Hashing24',
//     },
  
//   ];

//   return (
//     <div className='flex flex-col items-center justify-end'>
//       <h1 className="text-5xl font-bold mb-8" style={{ fontFamily: 'Roboto-Black' }}>Record High Numbers</h1>  
//       <div className="container mx-auto mt-8">
//         <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pr-40 pl-40">
//           {cryptoData.map((item, index) => (
//             <div key={index} className="bg-white flex flex-col items-center justify-center p-6 ">
//               <img src={item.image} alt={item.title} className="w-20 h-16 mb-4" />
//               <p className="text-gray-600 text-center text-slate-500 text-md" style={{ fontFamily: 'Roboto-Regular' }}>{item.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeAccessible;




// import React from 'react';
// import Arrows from "../../assets/others/arrows.png";
// import Euro from "../../assets/others/euro.png";
// import LockGray from "../../assets/others/lock gray.png";
// import LockYellow from "../../assets/others/lock yellow.png";
// import Percent from "../../assets/others/precent.png";
// import Question from "../../assets/others/question mark.png";


// const HomeAccessible = () => {
//   const cryptoData = [
//     {
//       image: Arrows,
//       title: 'Accessible to anyone',
//       description: 'Lightning fast transactions. Buy and sell with ease and peace of mind.',
//     },
//     {
//       image: Percent,
//       title: 'Lightning fast transactions',
//       description: 'Invest in cryptocurrency today with just a few clicks.',
//     },
//     {
//       image: Euro,
//       title: 'Buy and sell with ease and peace of mind',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//     },
//     {
//       image: Question,
//       title: 'Secure and reliable platform',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
//     },
//     {
//       image: LockYellow,
//       title: 'User-friendly interface',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
//     },
//     {
//       image: LockGray,
//       title: '24/7 Customer Support',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//     },
//   ];

//   return (
//     <div className='flex flex-col items-center justify-end h-screen'>
//       <h1 className="text-5xl font-bold mb-8">Accessible to anyone</h1>
//       <h3 className="text-xl mb-2 text-slate-500">
//         Lightning fast transactions. Buy and sell with ease and peace of mind.
//       </h3>
//       <h3 className="text-xl mb-4 text-slate-500">
//         Invest in cryptocurrency today with just a few clicks.
//       </h3>
//       <div className="container mx-auto mt-8">
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//           {cryptoData.map((item, index) => (
//             <div key={index} className="bg-white p-6 rounded-lg shadow-md">
//               <p>{item.image}</p>
//               <h2 className="text-xl font-semibold mb-4">{item.title}</h2>
//               <p className="text-gray-600">{item.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeAccessible;
