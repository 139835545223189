import React from "react";
import LoginHeader from "../components/Login/loginHeader";
import ForgetPassword from "../components/forgetPassword";

const Forget = () => {
  return (
    <div className="w-full bg-white" >
        <LoginHeader />
        <ForgetPassword />
      
    </div>
  );
};

export default Forget;