import React from 'react';

const StatisticsHeading = () => {
  return (
    <div className="p-10">
        <div className='border-b-2 border-white'>
            <h1 className="text-2xl md:text-4xl pb-2 font-bold text-black text-center sm:text-left" style={{ fontFamily: 'Roboto-Black' }}>DASHBOARD</h1>
        </div>
    </div>
  );
};

export default StatisticsHeading;