// // HomeVideo.js

// import React, { useState } from 'react';
// import Image from "../../assets/dashboard.webp";
// import Video from "../../assets/Videos/with outro.mp4";
// import VideoIcon from "../../assets/play icon png.png";

// const HomeVideo = () => {
//   const [showVideo, setShowVideo] = useState(false);

//   const openVideo = () => {
//     setShowVideo(!showVideo);
//   };

//   const closeVideo = () => {
//     setShowVideo(false);
//   };

//   return (
//     <div className="relative">
//       <div className="flex items-center justify-center h-screen relative">
//         <img
//           src={Image}
//           alt="Centered Image"
//           className={`rounded-lg shadow-lg cursor-pointer transition-transform transform`}
//           onClick={openVideo}
//         />
//         <img
//           src={VideoIcon}
//           alt="Play Video"
//           className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-34 h-22 cursor-pointer"
//           onClick={openVideo}
//         />

//         {showVideo && (
//           <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
//             <div className="relative">
//               <video
//                 title="Video"
//                 width="860"
//                 height="515"
//                 src={Video}
//                 autoPlay
//                 controls  
//                 className="rounded-lg shadow-lg"
//               ></video>
//               <div
//                 className="absolute top-2 right-2 cursor-pointer text-white"
//                 onClick={closeVideo}
//               >
//                 Close
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       {showVideo && (
//         <div
//           className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75"
//           onClick={closeVideo}
//         ></div>
//       )}
//     </div>
//   );
// };

// export default HomeVideo;


// // HomeVideo.js

// import React, { useState } from 'react';
// import Image from "../../assets/dashboard.webp";
// import Video from "../../assets/Videos/with outro.mp4";
// import VideoIcon from "../../assets/play icon png.png";

// const HomeVideo = () => {
//   const [showVideo, setShowVideo] = useState(false);

//   const openVideo = () => {
//     setShowVideo(!showVideo);
//   };

//   const closeVideo = () => {
//     setShowVideo(false);
//   };

//   return (
//     <div className="relative">
//       <div className="flex items-center justify-center h-screen relative">
//         <img
//           src={Image}
//           alt="Centered Image"
//           className={`rounded-lg shadow-lg cursor-pointer transition-transform transform `}
         
//         />
//         <img
//           src={VideoIcon}
//           alt="Play Video"
//           className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-34 h-22 cursor-pointer"
//           onClick={openVideo}
//         />

//         {showVideo && (
//           <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center  ">
//             <div className="relative">
//               <video
//                 title="Video"
//                 width="860"
//                 height="515"
//                 src={Video}
//                 autoPlay
//                 controls
//                 className=""
//               ></video>
//               <div
//                 className="absolute top-2 right-2 cursor-pointer text-white"
//                 onClick={closeVideo}
//               >
//                 Close
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       {showVideo && (
//         <div
//           className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75"
//           onClick={closeVideo}
//         ></div>
//       )}
//     </div>
//   );
// };

// export default HomeVideo;


// // HomeVideo.js

// import React, { useState } from 'react';
// import Image from "../../assets/dashboard.webp";
// import Video from "../../assets/Videos/with outro.mp4";
// import VideoIcon from "../../assets/play icon png.png";

// const HomeVideo = () => {
//   const [showVideo, setShowVideo] = useState(false);

//   const openVideo = () => {
//     setShowVideo(!showVideo);
//   };

//   const closeVideo = () => {
//     setShowVideo(false);
//   };

//   return (
//     <div className="relative">
//       <div className="flex items-center justify-center h-screen relative">
//         <img
//           src={Image}
//           alt="Centered Image"
//           className={`rounded-lg shadow-lg cursor-pointer transition-transform transform }`}
          
//         />
//         <img
//           src={VideoIcon}
//           alt="Play Video"
//           className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-34 h-22 cursor-pointer"
//           onClick={openVideo}
//         />

//         {showVideo && (
//           <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75">
//             <div className="relative">
//               <video
//                 title="Video"
//                 width="860"
//                 height="515"
//                 src={Video}
//                 autoPlay
//                 controls
//                 className=""
//               ></video>
//                <div
//                  className="absolute top-2 right-2 cursor-pointer text-white"
//                  onClick={closeVideo}
//                >
//                 Close
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       {showVideo && (
//         <div
//           className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75"
//           onClick={closeVideo}
//         ></div>
//       )}
//     </div>
//   );
// };

// export default HomeVideo;




// // HomeVideo.js

// import React, { useState } from 'react';
// import Image from "../../assets/dashboard.webp";
// import Video from "../../assets/Videos/without outro.mp4";
// import VideoIcon from "../../assets/play icon png.png";

// const HomeVideo = () => {
//   const [showVideo, setShowVideo] = useState(false);

//   const openVideo = () => {
//     setShowVideo(true);
//   };

//   const closeVideo = () => {
//     setShowVideo(false);
//   };

//   return (
//     <div className="relative">
//       <div className="flex items-center justify-center h-screen relative">
        
//         <img
//           src={Image}
//           alt="Centered Image"
//           className={`rounded-lg shadow-lg cursor-pointer ${showVideo && "filter blur-sm"}`}
//           onClick={openVideo}
//         />
        
//         <img
//           src={VideoIcon}
//           alt="Play Video"
//           className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-34 h-22 cursor-pointer"
//           onClick={openVideo}
//         />

//         {showVideo && (
//           <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75">
//             <div className="relative">
            
//               <video
//                 title="Video"
//                 width="760"
//                 height="515"
//                 src={Video}
//                 autoPlay
//                 controls
//                 className="rounded-lg shadow-lg"
//               ></video>
              
//               <div
//                 className="absolute top-2 right-2 cursor-pointer text-white"
//                 onClick={closeVideo}
//               >
//                 Close
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
      
//       {showVideo && (
//         <div
//           className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75"
//           onClick={closeVideo}
//         ></div>
//       )}
//     </div>
//   );
// };

// export default HomeVideo;


// // HomeVideo.js

// import React, { useState } from 'react';
// import Image from "../../assets/dashboard.webp";
// import Video from "../../assets/Videos/without outro.mp4";
// import VideoIcon from "../../assets/play icon png.png";

// const HomeVideo = () => {
//   const [showVideo, setShowVideo] = useState(false);

//   const openVideo = () => {
//     setShowVideo(true);
//   };

//   const closeVideo = () => {
//     setShowVideo(false);
//   };

//   return (
//     <div className="relative">
//       <div className="flex items-center justify-center h-screen relative">
       
//         <img
//           src={Image}
//           alt="Centered Image"
//           className="rounded-lg shadow-lg cursor-pointer"
//           onClick={openVideo}
//         />
        
//         <img
//           src={VideoIcon}
//           alt="Play Video"
//           className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-34 h-22  cursor-pointer"
//           onClick={openVideo}
//         />
//         {showVideo && (
//           <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center  bg-opacity-75">
//             <div className="relative">
//               <iframe
//                 title="Video"
//                 width="760"
//                 height="515"
//                 src={Video}
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//                 className="rounded-lg shadow-lg "
//                 controls
//               ></iframe>
             
//               <div
//                 className="absolute top-2 right-2 cursor-pointer"
//                 onClick={closeVideo}
//               >
//                 Close
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//       {showVideo && (
//         <>
//           <div
//             className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75"
//             onClick={closeVideo}
//           ></div>
         
//         </>
//       )}
//     </div>
//   );
// };

// export default HomeVideo;





// import React from 'react';
// import Image from "../../assets/dashboard.webp";

// const HomeVideo = () => {
//   return (
//     <div className="flex items-center justify-center h-screen">
//       <img
//         src={Image}
//         alt="Centered Image"
//         className="rounded-lg shadow-lg"
//       />
//     </div>
//   );
// };

// export default HomeVideo;
