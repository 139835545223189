import React from "react";

const HomePackages = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-black" style={{ fontFamily: 'Roboto-Black' }}>Our Packages Plans</h2>
          <p className="text-lg text-gray-600">Choose the plan that suits your needs.</p>
        </div>

        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          
          <div className="flex flex-col bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-2xl font-bold text-gray-900 mb-4"> Satoshi Starter Pack</h3>
            <p className="text-gray-600 mb-3">
            Initial Investment: 1 BTC
            </p>
            <p className="text-gray-600 mb-6"> Total Return: 2 BTCs (100% return on investment)</p>
            
            <div className="text-3xl font-bold text-gray-900 mb-4">Withdrawal Terms:</div>
            <ul className="mb-6 space-y-2 list-disc pl-5">
              <li className="text-gray-600">Entire amount available for withdrawal after 6 months.</li>
            </ul>
          </div>

          <div className="flex flex-col bg-white shadow-lg rounded-lg p-6 border-2 ">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">HODL Power Plan</h3>
            <p className="text-gray-600 mb-3">
            Initial Investment: 3 BTCs
            </p>
            <p className="text-gray-600 mb-6">
            Total Return: 10 BTCs
            </p>
            <div className="text-3xl font-bold text-gray-900 mb-4">Withdrawal Terms:</div>
            <ul className="mb-6 space-y-2 list-disc pl-5">
              <li className="text-gray-600"> 3 BTCs available for withdrawal after 6 months.</li>
              <li className="text-gray-600">Additional 3 BTCs available after another 6 months.</li>
              <li className="text-gray-600"> Remaining 4 BTCs available for withdrawal after 1 year.</li>
            </ul>
          </div>
          <div className="flex flex-col bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-2xl font-bold text-gray-900 mb-4"> Whale Pro Package</h3>
            <p className="text-gray-600 mb-3">
            Initial Investment: 7 BTCs
            </p>
            <p className="text-gray-600 mb-6">
            Total Return: 20 BTCs
            </p>
            <div className="text-3xl font-bold text-gray-900 mb-4">Withdrawal Terms:</div>
            <ul className="mb-6 space-y-2 list-disc pl-5">
              <li className="text-gray-600"> 7 BTCs available for withdrawal after 6 months.</li>
              <li className="text-gray-600">Additional 7 BTCs available after another 6 months.</li>
              <li className="text-gray-600">Remaining 6 BTCs available for withdrawal after 1 year.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePackages;
