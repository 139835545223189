import React from "react";
import TransfersForm from "../components/Transfers/transfersForm";
import DashboardHeader from "../components/dashboardHeader";
import TransfersTable from "../components/Transfers/transfersTable";
import TransfersHeading from "../components/Transfers/transfersHeading";



const Transfers = () => {
  return (
    <div className="w-full " style={{background: "#f7fafc"}}>
        <DashboardHeader />
        <TransfersHeading />
        <TransfersForm />
        <TransfersTable />
      
    </div>
  );
};

export default Transfers;