import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./GlobalCss.css";

const ForgetPassword = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [responseMessage, setResponseMessage] = useState(null);
  const navigate = useNavigate();

  const handleReset = async (event) => {
    event.preventDefault();
    if (email.trim() === "") {
      setError("Please fill in your email.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/auth/password/email`, {
        email,
      });
      window.location.href = "/confirm";
      if (response.status === 200) {
        setResponseMessage(
          response.data.message || "Password reset email sent successfully!"
        );
        setError("");
      }
    } catch (err) {
      console.error("Error during password reset request", err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
      setResponseMessage("");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (error) {
      setError("");
    }
  };

  return (
    <div className=" flex flex-col items-center justify-center p-6">
      <div className="max-w-md w-full">
        <h2
          className="text-3xl font-semibold mb-8 text-center"
          style={{ fontFamily: "Roboto-Black" }}
        >
          Forget Password
        </h2>
        <h4
          className="text-lg font-semibold mb-8 text-center"
          style={{ fontFamily: "Roboto-Light", color: "#1AB6FF" }}
        >
          You will receive an e-mail in maximum 60 seconds
        </h4>
        <form onSubmit={handleReset}>
          <div className="mb-6">
            <input
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="mt-1 p-4 text-lg w-full border rounded-md"
              required
            />
          </div>
          <button
            type="submit"
            className="text-white p-4 text-lg rounded-md hover:bg-blue-600 w-full"
            style={{ fontFamily: "Roboto-Regular", background: "#1AB6FF" }}
          >
            Submit
          </button>
          {responseMessage && (
            <p
              className="text-center mt-4"
              style={{ fontFamily: "Roboto-Regular", color: "#1AB6FF" }}
            >
              {responseMessage}
            </p>
          )}
          {error && (
            <p
              className="text-red-500 text-center mt-4"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {error}
            </p>
          )}
        </form>
        <div
          className="text-sm text-gray-600 mt-4 text-center"
          style={{ fontFamily: "Roboto-Regular" }}
        >
          Don’t have an account?{" "}
          <a
            href="/signup"
            className="hover:underline"
            style={{ fontFamily: "Roboto-Black", color: "#1AB6FF" }}
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

// import React , { useState} from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import "./GlobalCss.css";

// const ForgetPassword = () => {
//   const apiUrl = process.env.REACT_APP_BASE_URL;
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const [responseMessage, setResponseMessage] = useState(null);
//   const navigate = useNavigate();

//   const handleReset = async (event) => {
//     event.preventDefault();
//     if (email.trim() === "") {
//       setError("Please fill in your email");
//       return;
//     }

//     try {
//       await axios.post(`${apiUrl}/auth/password/email`, { email });
//       // window.location.href = "/confirm";
//     } catch (err) {
//       setError("An error occurred. Please try again.");
//     }
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//     if (error) {
//       setError("");
//     }
//   };

//   return (
//     <div className="min-h-screen flex flex-col items-center justify-center">
//       <div className="max-w-md w-full">
//         <h2 className="text-3xl font-semibold mb-8 text-center" style={{ fontFamily: 'Roboto-Black' }}>
//           Forget Password
//         </h2>
//         <h4 className="text-lg font-semibold mb-8 text-center " style={{ fontFamily: 'Roboto-Light' , color:'#1AB6FF', }}>
//           You will receive an e-mail in maximum 60 seconds
//         </h4>
//         <form onSubmit={handleReset}>
//           <div className="mb-6">
//             <input
//               placeholder="Email"
//               type="email"
//               id="email"
//               name="email"
//               value={email}
//               onChange={handleEmailChange}
//               className="mt-1 p-4 text-lg  w-full border rounded-md"
//               required
//             />
//           </div>
//           <button
//             type="submit"
//             className=" text-white   p-4 text-lg rounded-md hover:bg-blue-600 w-full"
//             style={{ fontFamily: 'Roboto-Regular', background:'#1AB6FF', }}
//           >
//             Submit
//           </button>
//           {responseMessage && (
//             <p className="text-blue-500 text-center mt-4" style={{ fontFamily: 'Roboto-Regular' }}>
//               {responseMessage}
//             </p>
//           )}
//           {error && (
//             <p className="text-red-500 text-center mt-4" style={{ fontFamily: 'Roboto-Regular' }}>
//               {error}
//             </p>
//           )}
//         </form>
//         <div className="text-sm text-gray-600 mt-4 text-center" style={{ fontFamily: 'Roboto-Regular' }}>
//           Don’t have an account?{' '}
//           <a href="/signup" className=" hover:underline" style={{ fontFamily: 'Roboto-Black', color:'#1AB6FF', }}>
//             Create an account
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgetPassword;
