import React, { useState } from 'react';
import "../GlobalCss.css";

const HomeEmail = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleGetStarted = () => {
    console.log(`Getting started with email: ${email}`);
  };

  return (
    <div className="flex  items-center justify-center mt-8 h-80" style={{ backgroundColor: '#f5f9fb' }}>
        <div className='flex flex-col mr-40'>
            <p className="text-5xl font-bold mb-4" style={{ fontFamily: 'Roboto-Black' }}>Start with Bitcoin now</p>
            <p className="mb-4 text-slate-500 text-3xl" style={{ fontFamily: 'Roboto-Light' }}>Get started with €5 or less</p>
        </div>
      
      <div className="flex  items-center ">
        <input
          type="email"
          placeholder="Email address"
          value={email}
          onChange={handleEmailChange}
          className="border px-24 py-5  "
        />
        <button
          onClick={handleGetStarted}
          className="bg-sky-500 text-white px-6 py-5 ml-10 rounded"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default HomeEmail;
