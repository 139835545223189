import React from "react";
import bg from "../../assets/others/At the risk of cherry-picking, the timing of the above chart fits with my supplydemand models below. Both charts suggests that bitcoin is not done with its current bullish p.png";

const HomeBannerSupply = () => {
  return (
    <div className="flex flex-col items-center justify-center px-6 py-12 md:p-20">
      <h3
        className="text-2xl md:text-4xl font-semibold mb-8"
        style={{ fontFamily: "Roboto-Black" }}
      >
        Bitcoin Supply & Demand Models
      </h3>
      <img src={bg} alt="Centered" className="w-full h-auto max-w-screen" />
    </div>
  );
};

export default HomeBannerSupply;
