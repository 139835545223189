import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const SettingBar = () => {
  const [selectedOption, setSelectedOption] = useState('profile');
  const [profileData, setProfileData] = useState({
    Name: '',
    Email: '',
    Profile_Creation: '',
  });
  const [newProfileData, setNewProfileData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [addProfileMessage, setAddProfileMessage] = useState('');
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
         if (!token) {
        navigate('/');
        } else {
          try {
             const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 28800;
              if (decodedToken.exp < currentTime) {
              localStorage.removeItem('accessToken');
             navigate('/');
              }
           } catch (error) {
               console.error("Invalid token", error);
              localStorage.removeItem('accessToken');
               navigate('/');
          }
         }
       }, [ navigate]);

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/user/profile/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Profile Data:', response.data);
      setProfileData(response.data.data || {
        Name: '',
        Email: '',
        Profile_Creation: '',
      });

      setNewProfileData({
        name: response.data.USER_DATA?.Name || '',
        email: response.data.USER_DATA?.Email || '',
        password: '',
      });
    } catch (error) {
      console.error('Error fetching profile data:', error);
      setError('Failed to fetch profile data. Please try again later.');
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleMenuClick = (option) => {
    setSelectedOption(option);
  };

  const handleProfileAdd = async (e) => {
    e.preventDefault();

    const updatedData = {};
    if (newProfileData.name) updatedData.name = newProfileData.name;
    if (newProfileData.email) updatedData.email = newProfileData.email;
    if (newProfileData.password) updatedData.password = newProfileData.password;
  
    console.log('Data being sent:', updatedData); 
  
    try {
      const response = await axios.post(
        `${apiUrl}/user/profile/update`,
        updatedData, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAddProfileMessage(response.data.message || 'Profile updated successfully!');
  
      fetchProfileData();
    } catch (error) {
      console.error('Error adding profile:', error);
      setAddProfileMessage(error.response?.data?.message || 'Error updating profile. Please try again.');
    }
  };

  const handleNewProfileInputChange = (e) => {
    const { name, value } = e.target;
    setNewProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const optionsContent = [
    {
      option: 'profile',
      title: 'View Profile',
      viewContent: [
        { label: 'Full name:', value: profileData.Name },
        { label: 'Email:', value: profileData.Email },
        { label: 'Profile Creation:', value: profileData.Profile_Creation },
      ],
    },
    {
      option: 'editProfile',
      title: 'Edit Profile',
      editableContent: [
        { label: 'Full name:', id: 'name', name: 'name', value: newProfileData.name }, 
        { label: 'Email:', id: 'email', name: 'email', value: newProfileData.email }, 
        { label: 'Password:', id: 'password', name: 'password', value: newProfileData.password }, 
      ],
    },
  ];

  return (
    <div className="flex flex-col md:flex-row md:mt-8">
      <div className="border-r-2 border-white-100 p-4 mb-12 md:p-8 max-h-96 overflow-y-auto md:mt-4">
        <ul>
          {optionsContent.map((content, index) => (
            <li
              key={index}
              onClick={() => handleMenuClick(content.option)}
              className={`cursor-pointer text-xl text-black hover:bg-gray-300 rounded-full mb-2 p-4 py-3 text-center transition-colors duration-150 ease-in-out ${
                selectedOption === content.option ? 'bg-gray-400 text-white' : ''
              }`}
            >
              {content.title}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex-1 p-4 md:p-16">
        <div className="flex justify-center">
          {optionsContent.map((content, index) => (
            <div key={index} className={selectedOption === content.option ? 'w-full' : 'hidden'}>
              {content.option === 'profile' && (
                <div className="flex justify-center mt-12 px-4 sm:px-6 lg:px-8">
                  <div className="border-2 border-white p-6 md:p-12 rounded-lg shadow-md w-full max-w-4xl bg-white">
                    <h2 className="text-lg md:text-3xl text-black text-center font-bold mb-6 break-words">PROFILE</h2>
                    {content.viewContent.map((item, index) => (
                      <div key={index} className="mb-4">
                        <p className="block text-gray-400 mb-2 break-words">
                          <strong className="text-black text-lg pr-2 break-words">{item.label}</strong> {item.value || 'N/A'}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {content.option === 'editProfile' && (
                <div className="flex justify-center mt-12 px-4 sm:px-6 lg:px-8">
                  <div className="border border-2 border-white p-6 md:p-12 rounded-lg shadow-md w-full max-w-4xl bg-white">
                    <h2 className="text-lg md:text-3xl text-black text-center font-bold mb-6">EDIT PROFILE</h2>
                    
                    <form onSubmit={handleProfileAdd}> 
                      {content.editableContent.map((item, index) => (
                        <div key={index} className="mb-4">
                          <label className="block text-black mb-2">{item.label}</label>
                          <input
                            type="text"
                            id={item.id}
                            name={item.name}
                            value={item.value || ''} 
                            onChange={handleNewProfileInputChange}
                            className="w-full p-2 rounded bg-gray-300 text-black border border-gray-500 focus:outline-none focus:border-gray-600"
                          />
                        </div>
                      ))}
                      <div className="flex items-center justify-center">
                        <button type="submit" className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded-full">
                          UPDATE
                        </button>
                      </div>
                      {addProfileMessage && (
                        <p className="text-orange-500 text-center mt-4">{addProfileMessage}</p>
                      )}
                    </form>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingBar;









// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// // import Lock from "../../assets/images/lock1.png";
// import { useNavigate } from "react-router-dom";
// // import { jwtDecode } from "jwt-decode";

// const SettingBar = () => {
//   const [selectedOption, setSelectedOption] = useState('profile');
//   const [profileData, setProfileData] = useState({
//           Name: '',
//           Email: '',
//           Profile_Creation: '',
//   });
//   const [newProfileData, setNewProfileData] = useState({
//     fullname: '',
//     country: '',
//     phone: '',
//   });
//   const [error, setError] = useState('');
//   const [userResponseMessage, setUserResponseMessage] = useState('');
//   const [passResponseMessage, setPassResponseMessage] = useState('');
//   const [addProfileMessage, setAddProfileMessage] = useState('');
//   const apiUrl = process.env.REACT_APP_BASE_URL;
//   const token = localStorage.getItem('accessToken');
//   const navigate = useNavigate();

// //   useEffect(() => {
// //     if (!token) {
// //     navigate('/');
// //     } else {
// //       try {
// //           const decodedToken = jwtDecode(token);
// //           const currentTime = Date.now() / 1000;
// //           if (decodedToken.exp < currentTime) {
// //           localStorage.removeItem('accessToken');
// //           navigate('/');
// //           }
// //       } catch (error) {
// //           console.error("Invalid token", error);
// //           localStorage.removeItem('accessToken');
// //           navigate('/');
// //       }
// //     }
// //   }, [ navigate]);

//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try { 
//         const response = await axios.get(`${apiUrl}/user/profile/show`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         console.log('Profile Data:', response.data);
//         setProfileData(response.data.USER_DATA || {
//           Name: '',
//           Email: '',
//           Profile_Creation: '',

//         });
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//         setError('Failed to fetch profile data. Please try again later.');
//       }
//     };

//     fetchProfileData();
//   }, [apiUrl]);

//   const handleMenuClick = (option) => {
//     setSelectedOption(option);
//   };

// //   const handlePasswordSubmit = async (e) => {
// //     e.preventDefault();
// //     const { currentPass, newPass, newPass2 } = e.target.elements;
// //     try {
// //       const response = await axios.put(
// //         `${apiUrl}/api/user/update/password`,
// //         {
// //           currentPass: currentPass.value,
// //           newPass: newPass.value,
// //           newPass2: newPass2.value,
// //         },
// //         {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //           },
// //         }
// //       );
// //       setUserResponseMessage(response.data.message || 'Password updated successfully!');
// //     } catch (error) {
// //       console.error('Error updating password:', error);
// //       setUserResponseMessage(error.response?.data?.message || 'Error updating password. Please try again.');
// //     }
// //   };

// //   const handleProfileUpdate = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const response = await axios.put(
// //         `${apiUrl}/api/user/update/data`,
// //         profileData,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //           },
// //         }
// //       );
// //       setProfileData(response.data.USER_DATA || profileData);
// //       setPassResponseMessage(response.data.message || 'Profile updated successfully!');
// //     } catch (error) {
// //       console.error('Error updating profile:', error);
// //       setPassResponseMessage(error.response?.data?.message || 'Error updating profile. Please try again.');
// //     }
// //   };

// //   const handleProfileAdd = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const response = await axios.post(
// //         `${apiUrl}/api/user/add/data`,
// //         newProfileData,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //           },
// //         }
// //       );
// //       setAddProfileMessage(response.data.message || 'Profile added successfully!');
// //       setNewProfileData({
// //         fullname: '',
// //         country: '',
// //         phone: '',
// //       });
// //     } catch (error) {
// //       console.error('Error adding profile:', error);
// //       setAddProfileMessage(error.response?.data?.message || 'Error adding profile. Please try again.');
// //     }
// //   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleNewProfileInputChange = (e) => {
//     const { name, value } = e.target;
//     setNewProfileData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const optionsContent = [
//     // {
//     //   option: 'addProfile',
//     //   title: 'Add Profile',
//     //   addContent: [
//     //     { label: 'Full name:', id: 'fullname', name: 'fullname', value: newProfileData.fullname },
//     //     { label: 'Country:', id: 'country', name: 'country', value: newProfileData.country },
//     //     { label: 'Phone:', id: 'phone', name: 'phone', value: newProfileData.phone },
//     //   ],
//     //   buttonText: 'ADD',
//     // },
//     {
//       option: 'profile',
//       title: 'View Profile',
//       viewContent: [
//         { label: 'Full name:', value: profileData.Name },
//         { label: 'EmaiL:', value: profileData.Email },
//         { label: 'Profile_Creation:', value: profileData.Profile_Creation },
//       ],
//     },
//     {
//       option: 'editProfile',
//       title: 'Edit Profile',
//       editableContent: [
//         { label: 'Full name:', id: 'fullname', name: 'fullname', value: profileData.fullname },
//         { label: 'EmaiL:', id: 'email', name: 'email', value: profileData.email },
//         { label: 'Country:', id: 'country', name: 'country', value: profileData.country },
//         { label: 'Phone:', id: 'phone', name: 'phone', value: profileData.phone },
//       ],
//     },
//     // {
//     //   option: 'updatePassword',
//     //   title: 'Update Password',
//     //   formElements: [
//     //     { id: 'currentPass', name: 'currentPass', placeholder: 'Enter Current Password' },
//     //     { id: 'newPass', name: 'newPass', placeholder: 'Enter New Password' },
//     //     { id: 'newPass2', name: 'newPass2', placeholder: 'Confirm New Password' },
//     //   ],
//     //   buttonText: 'UPDATE',
//     // },
   
//   ];

//   return (
//   <div className="flex flex-col md:flex-row md:mt-8">
//     <div className="border-r-2 border-white-100 p-4 mb-12 md:p-8 max-h-96 overflow-y-auto md:mt-4">
//       <ul>
//         {optionsContent.map((content, index) => (
//           <li
//             key={index}
//             onClick={() => handleMenuClick(content.option)}
//             className={`cursor-pointer text-xl text-black hover:bg-gray-300 rounded-full mb-2 p-4 py-3 text-center transition-colors duration-150 ease-in-out ${
//               selectedOption === content.option ? 'bg-gray-400 text-white' : ''
//             }`}
//           >
//             {content.title}
//           </li>
//         ))}
//       </ul>
//     </div>
//     <div className="flex-1 p-4 md:p-16">
//       <div className="flex justify-center">
//         {optionsContent.map((content, index) => (
//           <div key={index} className={selectedOption === content.option ? 'w-full' : 'hidden'}>
//             {/* {content.option === 'addProfile' && (
//               <div className="flex justify-center mt-12 px-4 sm:px-6 lg:px-8">
//                 <div className="border border-2 border-white-200 p-6 md:p-12 rounded-lg shadow-md w-full max-w-4xl bg-white">
//                   <h2 className="text-lg md:text-3xl text-black text-center font-bold mb-6">ADD PROFILE</h2>
                  
//                   <form >
//                     {content.addContent.map((item, index) => (
//                       <div key={index} className="mb-4">
//                         <label className="block text-black mb-2">{item.label}</label>
//                         <input
//                           type="text"
//                           id={item.id}
//                           name={item.name}
//                           value={item.value || ''}
//                           onChange={handleNewProfileInputChange}
//                           className="w-full p-2 rounded bg-gray-200 text-black border border-gray-500 focus:outline-none focus:border-gray-600"
//                         />
//                       </div>
//                     ))}
//                     <div className="flex items-center justify-center">
//                       <button type="submit" className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded-full">
//                         {content.buttonText}
//                       </button>
//                     </div>
//                     {addProfileMessage && (
//                       <p className="text-orange-500 text-center mt-4">{addProfileMessage}</p>
//                     )}
//                   </form>
//                 </div>
//               </div>
//             )} */}
//             {content.option === 'profile' && (
//               <div className="flex justify-center mt-12 px-4 sm:px-6 lg:px-8">
//                 <div className="border-2 border-white p-6 md:p-12 rounded-lg shadow-md w-full max-w-4xl bg-white">
//                   <h2 className="text-lg md:text-3xl text-black text-center font-bold mb-6 break-words">PROFILE</h2>
//                   {content.viewContent.map((item, index) => (
//                     <div key={index} className="mb-4">
//                       <p className="block text-gray-300 mb-2 break-words">
//                         <strong className="text-black text-lg pr-2 break-words">{item.label}</strong> {item.value || 'N/AA'}
//                       </p>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//             {content.option === 'editProfile' && (
//               <div className="flex justify-center mt-12 px-4 sm:px-6 lg:px-8">
//                 <div className="border border-2 border-white p-6 md:p-12 rounded-lg shadow-md w-full max-w-4xl bg-white">
//                   <h2 className="text-lg md:text-3xl text-black text-center font-bold mb-6">EDIT PROFILE</h2>
                  
//                   <form >
//                     {content.editableContent.map((item, index) => (
//                       <div key={index} className="mb-4">
//                         <label className="block text-black mb-2">{item.label}</label>
//                         <input
//                           type="text"
//                           id={item.id}
//                           name={item.name}
//                           value={item.value || ''}
//                           onChange={handleInputChange}
//                           className="w-full p-2 rounded bg-gray-300 text-black border border-gray-500 focus:outline-none focus:border-gray-600"
//                         />
//                       </div>
//                     ))}
//                     <div className="flex items-center justify-center">
//                       <button type="submit" className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded-full">
//                         UPDATE
//                       </button>
//                     </div>
//                     {passResponseMessage && (
//                       <p className="text-orange-500 text-center mt-4">{passResponseMessage}</p>
//                     )}
//                   </form>
//                 </div>
//               </div>
//             )}
//             {/* {content.option === 'updatePassword' && ( 
//                 <div className="flex justify-center mt-12 px-4 sm:px-6 lg:px-8">
//                   <div className="border border-2 border-white p-8 md:p-12 rounded-lg shadow-md w-full max-w-4xl bg-white">
//                     <div className="flex justify-center">
                     
//                     </div>
//                     <h2 className="text-2xl font-bold mb-4 mt-4 text-center text-black">CHANGE YOUR PASSWORD</h2>
                    
//                     <form>
//                       {content.formElements.map((item, index) => (
//                         <div key={index} className="mb-4">
//                           <label className="block text-black mb-2" htmlFor={item.id}>
//                             {item.placeholder}
//                           </label>
//                           <input
//                             type="password"
//                             id={item.id}
//                             name={item.name}
//                             className="w-full p-2 rounded bg-gray-300 text-white border border-gray-500 focus:outline-none focus:border-gray-600"
//                           />
//                         </div>
//                       ))}
//                       <div className="flex items-center justify-center">
//                         <button
//                           type="submit"
//                           className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded-full"
//                         >
//                           {content.buttonText}
//                         </button>
//                       </div>
//                       {userResponseMessage && (
//                         <p className="text-gray-500 text-center mt-4">{userResponseMessage}</p>
//                       )}
//                     </form>
//                   </div>
//                 </div>
//             )} */}
//           </div>
//         ))}
//       </div>
//     </div>
//   </div>
//   );
// };

// export default SettingBar;
