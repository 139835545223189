import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; 
import image1 from "../../assets/others/mine_myself--wattum.webp";
import image2 from "../../assets/others/mine_myself.webp";

const HomeSlider = () => {
  const slides = [
    {
      image: image1, 
      title: 'One-Stop Service for BTC Mining',
      content: 'By leveraging cutting-edge ASICs and optimized mining infrastructure, Wattum delivers superior efficiency and performance, enabling clients to achieve higher hash rates and maximize their mining rewards.',
    },
    {
      image: image2,
      title: 'Can I Start Mining on My Own?',
      content: 'Today, it is possible to mine Bitcoin on your own, but solo mining is too expensive and inefficient: specialized ASIC mining hardware is getting more complex and high-priced.',
    },
  ];

  return (
    <div className="container mx-auto p-4 mt-10 md:mt-20">
      <Splide
        options={{
          type: 'loop',
          autoplay: true,
          interval: 3000,
          pagination: false,
        }}
        className="w-full"
      >
        {slides.map((slide, index) => (
          <SplideSlide key={index}>
            <div className="flex flex-col md:flex-row items-center justify-center">
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <img
                  src={slide.image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-auto object-cover"
                />
              </div>
              <div className="w-full md:w-1/2 p-6 md:p-10 lg:p-20">
                <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">{slide.title}</h2>
                <p className="text-base md:text-lg">
                  {slide.content}
                </p>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default HomeSlider;




// import React from 'react';
// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import '@splidejs/react-splide/css'; 
// import image1 from "../../assets/others/mine_myself--wattum.webp";
// import image2 from "../../assets/others/mine_myself.webp";

// const HomeSlider = () => {
//   const slides = [
//     {
//       image: image1, 
//       title: 'One-Stop Service for BTC Mining',
//       content: 'By leveraging cutting-edge ASICs and optimized mining infrastructure, Wattum delivers superior efficiency and performance, enabling clients to achieve higher hash rates and maximize their mining rewards.',
//     },
//     {
//       image: image2,
//       title: 'Can I Start Mining on My Own?',
//       content: 'Today, it is possible to mine Bitcoin on your own, but solo mining is too expensive and inefficient: specialized ASIC mining hardware is getting more complex and high-priced.',
//     },
//   ];

//   return (
//     <div className="container mx-auto p-4 mt-20">
//       <Splide
//         options={{
//           type: 'loop',
//           autoplay: true,
//           interval: 3000,
//           pagination: false,
//         }}
//         className="w-full flex"
//       >
//         {slides.map((slide, index) => (
//           <SplideSlide key={index}>
//             <div className="flex items-center">
//               <div className="w-1/2">
//                 <img
//                   src={slide.image}
//                   alt={`Slide ${index + 1}`}
//                   className="w-full h-auto object-cover"
//                 />
//               </div>
//               <div className="w-1/2 p-20">
//                 <h2 className="text-5xl font-bold">{slide.title}</h2>
//                 <p className="mt-2 text-lg">
//                   {slide.content}
//                 </p>
//               </div>
//             </div>
//           </SplideSlide>
//         ))}
//       </Splide>
//     </div>
//   );
// };

// export default HomeSlider;



