import React from "react";
import AboutUsHeading from "../components/About/aboutHeading";
import AboutContent from "../components/About/aboutContent";
import AboutInfo from "../components/About/aboutInfo";


const About = () => {
  return (
    <div className="w-full bg-white" >
      {/* <AboutUsHeading /> */}
      <AboutContent />
      <AboutInfo />
    </div>
  );
};

export default About;