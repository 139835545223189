import React from 'react';
import bg from "../../assets/others/ADD TO SELL LIST (1).png";

const HomeBannerTrading = () => {
  return (
    <div className="flex items-center justify-center px-6 py-12 md:p-20">
      <img
        src= {bg}
        alt="Centered"
        className="w-full h-full max-w-screen"
      />
    </div>
  );
};

export default HomeBannerTrading;