import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../GlobalCss.css";

const LoginForm = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();


  const handleEmailChange = (event) => setEmail(event.target.value);

  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleShowPasswordToggle = () => setShowPassword(!showPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");

    try {
      const response = await axios.post(`${apiUrl}/auth/login`, {
        email,
        password,
      });

      console.log("Response data:", response.data);

      if (response.status === 200) {
        const accessToken = response.data.access_token;

        localStorage.setItem("accessToken", accessToken);

        console.log("Access Token:", accessToken);
        navigate("/statistics");
      } else {
        console.error("Access token not found in response.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      }
    }
  };

  axios.interceptors.request.use(
    function (config) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return (
    <div className="flex flex-col items-center justify-center p-6 ">
      <div className="max-w-md w-full">
        <h2
          className="text-3xl font-semibold mb-8 text-center"
          style={{ fontFamily: "Roboto-Black" }}
        >
          Log in to Tokonite
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <input
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="mt-1 p-4 text-lg w-full border rounded-md"
              required
            />
          </div>
          <div className="mb-2 relative">
            <input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              className="mt-1 p-4 text-lg w-full border rounded-md"
              required
            />
          </div>
          <div className="flex items-center justify-between mb-4 w-full whitespace-nowrap">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={handleShowPasswordToggle}
                className="mr-2"
              />
              <label
                htmlFor="showPassword"
                className="text-sm"
                style={{ fontFamily: "Roboto-Regular" }}
              >
                Show Password
              </label>
            </div>
            <a
              href="/forget"
              className="text-sm hover:underline"
              style={{ fontFamily: "Roboto-Regular", color: "#1AB6FF" }}
            >
              Forgot password?
            </a>
        </div>
          <button
            type="submit"
            className="text-white p-4 text-lg rounded-md hover:bg-blue-600 w-full"
            style={{ fontFamily: "Roboto-Regular", background: "#1AB6FF" }}
          >
            Log in
          </button>
          {errorMessage && (
            <p
              className="text-red-500 text-center mt-4"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {errorMessage}
            </p>
          )}
        </form>
        <div
          className="text-sm text-gray-600 mt-4 text-center"
          style={{ fontFamily: "Roboto-Regular" }}
        >
          Don’t have an account?{" "}
          <a
            href="/signup"
            className="hover:underline"
            style={{ fontFamily: "Roboto-Black", color: "#1AB6FF" }}
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;





// import React, { useState } from "react";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import "../GlobalCss.css";

// const LoginForm = () => {
//   const apiUrl = process.env.REACT_APP_BASE_URL;
//   const [rememberMe, setRememberMe] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();

//   const handleSetRememberMe = () => setRememberMe(!rememberMe);

//   const handleEmailChange = (event) => setEmail(event.target.value);

//   const handlePasswordChange = (event) => setPassword(event.target.value);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setErrorMessage("");

//     try {
//       const response = await axios.post(`${apiUrl}/auth/login`, {
//         email,
//         password,
//       });

//       console.log("Response data:", response.data);

//       if (response.status === 200) {
//         const accessToken = response.data.access_token;

//         localStorage.setItem("accessToken", accessToken);

//         console.log("Access Token:", accessToken);
//         navigate("/statistics");
//         // window.location.href = "/prototype";
//       } else {
//         console.error("Access token not found in response.");
//       }
//     } catch (error) {
//       if (error.response && error.response.data && error.response.data.error) {
//                 setErrorMessage(error.response.data.error);
//               }
//     }
//   };

//   axios.interceptors.request.use(
//     function (config) {
//       const accessToken = localStorage.getItem("accessToken");
//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }
//       return config;
//     },
//     function (error) {
//       return Promise.reject(error);
//     }
//   );

//   return (
//     <div className=" flex flex-col items-center justify-center p-6">
//       <div className="max-w-md w-full">
//         <h2
//           className="text-3xl font-semibold mb-8 text-center"
//           style={{ fontFamily: "Roboto-Black" }}
//         >
//           Log in to Tokonite
//         </h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-6">
//             <input
//               placeholder="Email"
//               type="email"
//               id="email"
//               name="email"
//               value={email}
//               onChange={handleEmailChange}
//               className="mt-1 p-4 text-lg  w-full border rounded-md"
//               required
//             />
//           </div>
//           <div className="mb-2">
//             <input
//               placeholder="Password"
//               type="password"
//               id="password"
//               name="password"
//               value={password}
//               onChange={handlePasswordChange}
//               className="mt-1 p-4 text-lg  w-full border rounded-md"
//               required
//             />
//           </div>
//           <div className="mb-4 text-right">
//             <a
//               href="/forget"
//               className="text-sm  hover:underline"
//               style={{ fontFamily: "Roboto-Regular", color: "#1AB6FF" }}
//             >
//               Forgot password?
//             </a>
//           </div>
//           <button
//             type="submit"
//             className=" text-white   p-4 text-lg rounded-md hover:bg-blue-600 w-full"
//             style={{ fontFamily: "Roboto-Regular", background: "#1AB6FF" }}
//           >
//             Log in
//           </button>
//           {errorMessage && (
//             <p
//               className="text-red-500 text-center mt-4"
//               style={{ fontFamily: "Roboto-Regular" }}
//             >
//               {errorMessage}
//             </p>
//           )}
//         </form>
//         <div
//           className="text-sm text-gray-600 mt-4 text-center"
//           style={{ fontFamily: "Roboto-Regular" }}
//         >
//           Don’t have an account?{" "}
//           <a
//             href="/signup"
//             className=" hover:underline"
//             style={{ fontFamily: "Roboto-Black", color: "#1AB6FF" }}
//           >
//             Create an account
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginForm;





