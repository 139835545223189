import React from 'react';
import VideoTracker from '../../assets/others/Your paragraph text.png';
import "../GlobalCss.css";

const HomePortfolioTracker = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center p-8 md:p-20">
      <div className="w-full md:w-1/2 md:ml-10 lg:ml-40 mb-6 md:mb-0">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-6" style={{ fontFamily: 'Roboto-Black' }}>High-Tech Mining Data Centers</h1>
        <p className="text-slate-500 text-lg md:text-xl mb-4" style={{ fontFamily: 'Roboto-Regular' }}>
        Wattum leverages progressive mining ASICs to ensure optimal performance and efficiency in Bitcoin mining operations. These ASICs are purpose-built chips designed specifically for mining cryptocurrencies, particularly Bitcoin, and offer significant advantages over traditional CPU or GPU mining methods.
        </p>
        <p className="text-slate-500 text-lg md:text-xl mb-8" style={{ fontFamily: 'Roboto-Regular' }}>
        Some of the progressive mining ASICs utilized by Wattum include models from industry-leading manufacturers such as Bitmain, MicroBT, and Canaan Creative. These ASICs are equipped with high-performance hashing algorithms (e.g., SHA-256), one of the most secure algorithms globally.
        </p>
      </div>
      <div className="w-full md:w-1/2 md:mr-10 lg:mr-40 p-4 md:p-10">
        <img
          title="Crypto Video"
          width="100%"
          height="100%"
          src={VideoTracker}
          alt="Crypto Video"
        />
      </div>
    </div>
  );
};

export default HomePortfolioTracker;

