import React from 'react';
import bg from "../../assets/logos/Tokonite.png";

const HomeBannerDemo = () => {
  return (
    <div className="flex items-center justify-center px-6 py-12 md:p-20">
      <img
        src={bg}
        alt="Centered Banner"
        className="w-full h-auto max-w-screen"
      />
    </div>
  );
};

export default HomeBannerDemo;




// import React from 'react';
// import bg from "../../assets/others/demo.png";

// const HomeBannerDemo = () => {
//   return (
//     <div className="flex items-center justify-center p-20">
//       <img
//         src= {bg}
//         alt="Centered"
//         className="w-full h-auto"
//       />
//     </div>
//   );
// };

// export default HomeBannerDemo;
