import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/CoinFalcon Logo.png";
import "../GlobalCss.css";

const LoginHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between p-8">
      <a href="/" className="flex items-center">
        <img src={Logo} alt="Logo" className="h-12 w-12 " />
        <span
          className=" text-sm text-black font-extrabold "
          style={{ fontFamily: "Roboto-Medium" }}
        >TOKONITE
        </span>
      </a>
      <div className="flex items-center">
        <span
          className="md:mr-4 text-slate-500"
          style={{ fontFamily: "Roboto-Regular" }}
        >
          Don't have an account?
        </span>
        <button
          className="border border-gray  px-4 py-2 rounded text-sm"
          onClick={() => navigate("/signup")}
          style={{ fontFamily: "Roboto-Medium" }}
        >
          Sign up
        </button>
      </div>
    </div>
  );
};

export default LoginHeader;
