import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import About from "./pages/about";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Prices from "./pages/prices";
import Forget from "./pages/forget";
import Confirm from "./pages/confirm";
import Statistics from "./pages/satistics";
import Setting from "./pages/setting";
import Transfers from "./pages/transfers";

// function Layout({ children }) {
//   const [isLoginOrSignup, setIsLoginOrSignup] = useState(false);

//   useEffect(() => {
//     setIsLoginOrSignup(
//       window.location.pathname.includes("/login") ||  window.location.pathname.includes("/signup") || window.location.pathname.includes("/statistics") ||window.location.pathname.includes("/dashboard") ||
//       window.location.pathname.includes("/forget") || window.location.pathname.includes("/confirm") || 
//       window.location.pathname.includes("/setting")
//     );
//   }, [window.location.pathname]);

//   return (
//     <>
//       {!isLoginOrSignup ? <Header /> : null}
//       {children}
//       {!isLoginOrSignup ? <Footer /> : null}
//     </>
//   );
// }

function App() {
  return (
    <div className="App">
      <Router>
        {/* <Layout> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forget" element={<Forget />} />
            <Route path="/confirm" element={<Confirm />} />
            <Route path="/statistics" element={<Statistics />} />
            <Route path="/transfers" element={<Transfers />} />
            <Route path="/setting" element={<Setting />} />
          </Routes>
        {/* </Layout> */}
      </Router>
    </div>
  );
}

export default App;











// import React from "react";
// import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
// import Header from "./components/header";
// import Footer from "./components/footer";
// import Home from "./pages/home";
// import About from "./pages/about";
// import Login from "./pages/login";
// import Signup from "./pages/signup";


// function App() {
//   return (
//     <div className="App">
//       <Router>
//         <Header />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />       
//         </Routes> 
        
//         <Footer />
//       </Router>
//     </div>
//   );
// }

// export default App;


