import React from "react";
import SignupHeader from "../components/Signup/signupHeader";
import SignupForm from "../components/Signup/signupForm";

const Signup = () => {
  return (
    <div className="w-full bg-white" >
        <SignupHeader />
        <SignupForm /> 
    </div>
  );
};

export default Signup;