import React from 'react';
import "../GlobalCss.css"; 

const AboutContent = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-24 lg:px-96 mt-6" >
      <h2 className="text-6xl font-extrabold mt-6 mb-6 " style={{ fontFamily: 'Roboto-Black' }}>About us</h2>
      <div style={{color :"#6D859E" , fontFamily: 'Roboto-Regular'}}>
        <p className="text-lg mb-4 ">
          CoinFalcon was launched in October 2017 with the mission to put the future of money in the pockets of the world.
          Since then, it has grown into a trusted platform where merchants and consumers can transact with digital currencies
          like Bitcoin, Ethereum, Litecoin, Ripple, and many more. We're based in the United Kingdom. Our team currently
          consists of over 20 ambitious and talented members from all over the globe, who are not only great at what they do
          but are equally passionate about turning our mission into reality.
        </p>

        <p className="text-2xl mb-4">
          Our mission at CoinFalcon is to put the future of money, in the pockets of the world.
        </p>

        <p className="text-lg mb-4">
          At its core, CoinFalcon is all about ease of use. To stay ahead of the curve, we continuously work on
          strengthening CoinFalcon's position as Europe's leading platform for digital assets while expanding our scope to
          introduce new services.
        </p>

        <p className="text-lg mb-4">
          For too long, our shallow pockets have been a reminder of the illusion of control centralized institutions have
          sewed upon us. Day by day, we tear a stitch, seeing the glimmer, a faint sliver of what our money, our very
          lives, could look like truly unencumbered by an aged system.
        </p>

        <p className="text-lg mb-4">
          With cryptocurrency, we have broken every stitch and found the true depth our pockets have yearned to provide for
          our financial future. Fighting by your side, CoinFalcon will help guide you along this path, by putting the
          future of money, in your pockets.
        </p>

        <p className="text-lg mb-4">
          Cryptocurrency is freedom, let us liberate!
        </p>

        <h2 className="text-2xl  mb-4">Pushing the Industry Forward</h2>

        <p className="text-lg mb-4">
          At CoinFalcon, we are firm believers in the innovative and revolutionary power of blockchain technology,
          cryptocurrencies, and digitized assets. We are guided by our commitment to tearing down the outdated systems of
          personal finance and bringing in the new era of financial products and technological advancements into the 21st
          century.
        </p>

        <p className="text-lg mb-4">
          That’s why in addition to accepting SEPA international bank transfers, our platform is also fully optimized for
          credit/debit cards for fiat currency payments for buying cryptocurrencies.
        </p>

        <p className="text-lg mb-4">
          With a streamlined user experience and high-grade online security systems, CoinFalcon has blossomed into a popular
          and trusted trading platform for experts and newbies alike.
        </p>

        <p className="text-lg mb-4">
          Users can currently trade Bitcoin, Ethereum, EOS, Electra, USDT, and other digital assets. Our user-friendly
          interface, available on <a href="/support" className="text-blue-500">iOS</a> and <a href="/support" className="text-blue-500"> Android</a>, lets you buy, sell, and HODL different kinds of crypto assets with
          great ease and assurance. We also feature low transaction fees and super-fast transactions to guarantee a
          seamless trading experience.
        </p>

        <p className="text-lg mb-4">
          Want to learn more about us? Check out our <a href="/support" className="text-blue-500">Support Page</a> or shoot us a message at{' '}
          <a href="mailto:support@coinfalcon.com" className="text-blue-500">support@coinfalcon.com</a>.
        </p>
      </div>
    </div>
  );
};

export default AboutContent;
