import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { FiCopy } from 'react-icons/fi'; 
import BTC from "../../assets/logos/bitcoin png.png";
import { jwtDecode} from "jwt-decode";  

const StatisticsBox = () => {
  const [btcAddress, setBtcAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('accessToken');
  
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 28800;
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem('accessToken');
          navigate('/');
        }
        setLoading(false);  
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.removeItem('accessToken');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate, token]);  

  useEffect(() => {
    if (!loading && token) {
      axios.get(`${apiUrl}/user/wallet/address`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          setBtcAddress(response.data.address);
        })
        .catch(error => {
          console.error('Error fetching BTC address:', error);
        });

      axios.get(`${apiUrl}/user/wallet/address/amount`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          setAmount(response.data.amount);
        })
        .catch(error => {
          console.error('Error fetching amount:', error);
        });
    }
  }, [apiUrl, token, loading]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(btcAddress);
    alert('BTC Address copied to clipboard!');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='flex flex-col items-center mt-5 px-4'>
      <div className="flex flex-col items-center bg-white border shadow-md p-6 sm:p-10 w-full max-w-xl">
        <div className='flex flex-row mb-4'>
          <h1
            className="text-xl sm:text-3xl font-bold text-black text-center sm:text-left"
            style={{ fontFamily: 'Roboto-Black' }}
          >
            {`AMOUNT:  ${amount} `}
          </h1>
        </div>
        <div className='flex flex-col sm:flex-row items-center justify-center sm:justify-start'>
          <img src={BTC} alt="BTC" className='w-10 h-10 mb-4 sm:mb-0 sm:mr-4' />
          <h1
            className="text-xl sm:text-3xl font-bold text-black text-center sm:text-left"
            style={{ fontFamily: 'Roboto-Black' }}
          >
            BTC ADDRESS
          </h1>
        </div>
        <div className='flex flex-col sm:flex-row items-center justify-center sm:justify-start mt-4'>
          <h3 className='text-sm sm:text-lg text-center sm:text-left mb-2 sm:mb-0 break-all'>
            {btcAddress || 'Loading...'}
          </h3>
          <button onClick={copyToClipboard} className="text-gray-400 hover:text-blue-500 sm:ml-4 mt-2 sm:mt-0">
            <FiCopy size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatisticsBox;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import { FiCopy } from 'react-icons/fi'; 
// import BTC from "../../assets/logos/bitcoin png.png";
//  import { jwtDecode } from "jwt-decode";



// const StatisticsBox = () => {
//   const [btcAddress, setBtcAddress] = useState('');
//   const [amount, setAmount] = useState('');
//   const apiUrl = process.env.REACT_APP_BASE_URL;
//   const token = localStorage.getItem('accessToken');

//   const navigate = useNavigate();

//   const [loading, setLoading] = useState(true);


//   useEffect(() => {
//          if (!token) {
//         navigate('/');
//         } else {
//           try {
//              const decodedToken = jwtDecode(token);
//             const currentTime = Date.now() / 1000;
//               if (decodedToken.exp < currentTime) {
//               localStorage.removeItem('accessToken');
//              navigate('/');
//               }
//            } catch (error) {
//                console.error("Invalid token", error);
//               localStorage.removeItem('accessToken');
//                navigate('/');
//           }
//          }
//        }, [ navigate]);

//   useEffect(() => {
//     axios.get(`${apiUrl}/user/wallet/address`,
//       {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then(response => {
//         setBtcAddress(response.data.address);
//       })
//       .catch(error => {
//         console.error('Error fetching BTC address:', error);
//       });

//     axios.get(`${apiUrl}/user/wallet/address/amount`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then(response => {
//         setAmount(response.data.amount);
//       })
//       .catch(error => {
//         console.error('Error fetching amount:', error);
//       });
//   }, [apiUrl]);

//   const copyToClipboard = () => {
//     navigator.clipboard.writeText(btcAddress);
//     alert('BTC Address copied to clipboard!');
//   };

//   return (
//     <div className='flex flex-col items-center mt-5 px-4'>
//       <div className="flex flex-col items-center bg-white border shadow-md p-6 sm:p-10 w-full max-w-xl">
//         <div className='flex flex-row mb-4'>
//           <h1
//             className="text-xl sm:text-3xl font-bold text-black text-center sm:text-left"
//             style={{ fontFamily: 'Roboto-Black' }}
//           >
//             {`AMOUNT:  ${amount} `}
//           </h1>

//         </div>
//         <div className='flex flex-col sm:flex-row items-center justify-center sm:justify-start'>
//           <img src={BTC} alt="BTC" className='w-10 h-10 mb-4 sm:mb-0 sm:mr-4' />
//           <h1
//             className="text-xl sm:text-3xl font-bold text-black text-center sm:text-left"
//             style={{ fontFamily: 'Roboto-Black' }}
//           >
//             BTC ADDRESS
//           </h1>
//         </div>
//         <div className='flex flex-col sm:flex-row items-center justify-center sm:justify-start mt-4'>
//           <h3 className='text-sm sm:text-lg text-center sm:text-left mb-2 sm:mb-0 break-all'>
//             {btcAddress || 'Loading...'}
//           </h3>
//           <button onClick={copyToClipboard} className="text-gray-400 hover:text-blue-500 sm:ml-4 mt-2 sm:mt-0">
//             <FiCopy size={20} />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatisticsBox;





// import React from 'react';
// import { FiCopy } from 'react-icons/fi'; 
// import BTC from "../../assets/logos/bitcoin png.png";

// const StatisticsBox = () => {
//   const btcLink = 'jdsahf93q4987qghhgiht9743y9qyr943b';

//   const copyToClipboard = () => {
//     navigator.clipboard.writeText(btcLink);
//     alert('Link copied to clipboard!');
//   };

//   return (
//     <div className='flex flex-col items-center mt-5 px-4'>
//       <div className="flex flex-col items-center bg-white border shadow-md p-6 sm:p-10 w-full max-w-xl">
//         <div className='flex flex-row mb-4'>
//           <h1
//             className="text-xl sm:text-3xl font-bold text-black text-center sm:text-left"
//             style={{ fontFamily: 'Roboto-Black' }}
//           >
//             AMOUNT
//           </h1>
//           <h1
//             className="text-xl sm:text-3xl font-bold text-black text-center sm:text-left ml-2"
//             style={{ fontFamily: 'Roboto-Black' }}
//           >
//             BTC ADDRESS
//           </h1>
//         </div>
//         <div className='flex flex-col sm:flex-row items-center justify-center sm:justify-start'>
//           <img src={BTC} alt="BTC" className='w-10 h-10 mb-4 sm:mb-0 sm:mr-4' />
//           <h1
//             className="text-xl sm:text-3xl font-bold text-black text-center sm:text-left"
//             style={{ fontFamily: 'Roboto-Black' }}
//           >
//             BTC ADDRESS
//           </h1>
//         </div>
//         <div className='flex flex-col sm:flex-row items-center justify-center sm:justify-start mt-4'>
//           <h3 className='text-sm sm:text-lg text-center sm:text-left mb-2 sm:mb-0 break-all'>
//             {btcLink}
//           </h3>
//           <button onClick={copyToClipboard} className="text-gray-400 hover:text-blue-500 sm:ml-4 mt-2 sm:mt-0">
//             <FiCopy size={20} />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatisticsBox;







