import React from 'react';
import "../GlobalCss.css";
import { FaCheckCircle } from 'react-icons/fa'; 

const HomeHashing = () => {
  const cryptoData = [
    {
      title: 'Low entry point',
      description: 'You can start from 1 TH/s — get into BTC cloud mining with minimum investments.',
    },
    {
      title: '10 years on the market',
      description: 'The company has been operating since 2012 onwards. Legal entity registered in Ireland.',
    },
    {
      title: 'Brand recognition',
      description: 'Tokonite has over 200 000 active users and the number keeps growing progressively.',
    },
    {
      title: 'Innovative technologies',
      description: 'Top-performing and high-tech immersion cooling systems for the fastest BTC mining hardware.',
    },
    {
      title: 'Non-stop monitoring 24/7/365',
      description: 'Enjoy non-stop Bitcoin mining 24/7 monitored by qualified personnel in massive data centers.',
    },
    {
      title: 'Official partner of Bitfury',
      description: 'Tokonite is the one and only official partner of BTC mining and blockchain technology giant Bitfury.',
    },
    {
      title: 'Easy start and Demo mining',
      description: 'Start BTC cloud mining in just a few minutes. Try free Demo mining with real market conditions.',
    },
    {
      title: 'US-based BTC mining solutions',
      description: 'Our hash power supplier Wattum is one of the largest providers of BTC mining solutions in the US.',
    },
    {
      title: 'Customer support 24/7',
      description: 'Dedicated and professional customer support services available round-the-clock.',
    },
  ];

  return (
    <div className='flex flex-col items-center justify-center mt-10 px-4'>
      <h3 className="text-3xl md:text-4xl font-semibold mb-6 text-center" style={{ fontFamily: 'Roboto-Black' }}>Why Tokonite?</h3>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {cryptoData.map((item, index) => (
            <div key={index} className="flex flex-col items-center p-4 md:p-6 bg-white rounded-lg shadow-lg">
              <div className="flex items-center mb-4">
                <FaCheckCircle className="w-6 h-6 mr-2" style={{ color: '#1AB6FF' }} /> 
                <h2 className="text-base md:text-lg font-semibold" style={{ fontFamily: 'Roboto-Black' }}>
                  {item.title}
                </h2>
              </div>
              <p className="text-gray-600 text-sm md:text-md text-center" style={{ fontFamily: 'Roboto-Regular' }}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeHashing;





// import React from 'react';
// import "../GlobalCss.css";
// import { FaCheckCircle } from 'react-icons/fa'; 

// const HomeHashing = () => {
//   const cryptoData = [
//     {
//       title: 'Low entry point',
//       description: 'You can start from 1 TH/s — get into BTC cloud mining with minimum investments.',
//     },
//     {
//       title: '10 years on the market',
//       description: 'The company has been operating since 2012 onwards. Legal entity registered in Ireland. ',
//     },
//     {
//       title: 'Brand recognition',
//       description: 'Hashing24 has over 200 000 active users and the number keeps growing progressively.',
//     },
//     {
//       title: 'Innovative technologies',
//       description: 'Top-performing and high-tech immersion cooling systems for the fastest BTC mining hardware.',
//     },
//     {
//       title: 'Non-stop monitoring 24/7/365',
//       description: 'Enjoy non-stop Bitcoin mining 24/7 monitored by qualified personnel in massive data centers.',
//     },
//     {
//         title: 'Official partner of Bitfury',
//         description: 'Hashing24 is the one and only official partner of BTC mining and blockchain technology giant Bitfury.',
//       },
//       {
//         title: 'Easy start and Demo mining',
//         description: 'Start BTC cloud mining in just a few minutes. Try free Demo mining with real market conditions.',
//       },
//       {
//         title: 'US-based BTC mining solutions',
//         description: 'Our hash power supplier Wattum is one of the largest providers of BTC mining solutions in the US.',
//       },
//       {
//           title: 'Customer support 24/7',
//           description: 'Dedicated and professional customer support services available round-the-clock.',
//         },
  
//   ];

//   return (
//     <div className='flex flex-col items-center justify-end mt-20'>
//       <h3 className="text-4xl font-semibold mb-2" style={{ fontFamily: 'Roboto-Black' }}>Why Hashing24?</h3>
//       <div className="container mx-auto mt-8">
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pr-40 pl-40">
//           {cryptoData.map((item, index) => (
//             <div key={index} className="flex flex-col items-center justify-center p-6 ">
//               <div className="flex items-center mb-2">
//                 <FaCheckCircle className=" mr-2" style={{ color: '#1AB6FF' }}/> 
//                 <h2 className="text-lg font-semibold" style={{ fontFamily: 'Roboto-Black' }}>
//                   {item.title}
//                 </h2>
//               </div>
//               <p className="text-gray-600 text-center text-slate-500 text-md" style={{ fontFamily: 'Roboto-Regular' }}>{item.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeHashing;