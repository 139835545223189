import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import "../GlobalCss.css";

const StatisticsTable2 = () => {
  const [txData, setTxData] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 
  const token = localStorage.getItem('accessToken'); 
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/wallet/address/txs/withdrawl`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        console.log("API Response:", response.data); 
  
        if (Array.isArray(response.data)) {
          setTxData(response.data);
        } else {
          console.error("Unexpected response format");
          setTxData([]);
        }
      } catch (error) {
        console.error("Error fetching data", error);
        setTxData([]); 
      }
    };
  
    fetchData();
  }, [token, apiUrl]);

  
  const totalPages = Math.ceil(txData.length / itemsPerPage);

  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = txData.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex flex-col items-center pt-10 pb-10 w-full lg:w-1/2 px-4 lg:px-0">
      <div className="overflow-x-auto w-full">
        <table className="bg-white border shadow-md min-w-full">
          <thead>
            <tr>
              <th colSpan="3" className="p-4 lg:p-8">
                <div className="flex justify-between">
                  <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
                    Withdrawal History
                  </h1>
                </div>
              </th>
            </tr>
            <tr>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>TXID</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Amount</th>
              <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Confirmed</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.length > 0 ? (
              currentTransactions.map((tx, index) => (
                <tr key={index} className="hover:bg-gray-100 transition duration-200">
                  <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>
                    <a 
                      href={`https://blockstream.info/tx/${tx.txid}`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      {`${tx.txid.slice(0, 15)}...${tx.txid.slice(-15)}`}
                    </a>
                  </td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.amount}</td>
                  <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.confirmed ? "Yes" : "No"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="p-4 text-center">No transactions found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      <div className="flex justify-between w-full mt-4">
        <button 
          onClick={handlePrevPage} 
          disabled={currentPage === 1} 
          className={`px-4 py-2 bg-gray-300 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'}`}
        >
          Previous
        </button>
        <span className="self-center">{`Page ${currentPage} of ${totalPages}`}</span>
        <button 
          onClick={handleNextPage} 
          disabled={currentPage === totalPages} 
          className={`px-4 py-2 bg-gray-300 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'}`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StatisticsTable2;




// import React, { useEffect, useState } from 'react';
// import axios from 'axios'; 
// import "../GlobalCss.css";

// const StatisticsTable2 = () => {
//   const [txData, setTxData] = useState([]); 
//   const token = localStorage.getItem('accessToken'); 
//   const apiUrl = process.env.REACT_APP_BASE_URL;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/user/wallet/address/txs/withdrawl`, {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         });
  
//         console.log("API Response:", response.data); 
  
//         if (Array.isArray(response.data)) {
//           setTxData(response.data);
//         } else {
//           console.error("Unexpected response format");
//           setTxData([]);
//         }
//       } catch (error) {
//         console.error("Error fetching data", error);
//         setTxData([]); 
//       }
//     };
  
//     fetchData();
//   }, [token, apiUrl]);
  
//   return (
//     <div className="flex flex-col items-center pt-10 pb-10 w-full lg:w-1/2 px-4 lg:px-0">
//       <div className="overflow-x-auto w-full">
//         <table className="bg-white border shadow-md min-w-full">
//           <thead>
//             <tr>
//               <th colSpan="3" className="p-4 lg:p-8">
//                 <div className="flex justify-between">
//                   <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
//                   Withdrawal History
//                   </h1>
//                 </div>
//               </th>
//             </tr>
//             <tr>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>TXID</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Amount</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Confirmed</th>
//             </tr>
//           </thead>
//           <tbody>
//             {txData.length > 0 ? (
//               txData.map((tx, index) => (
//                 <tr key={index} className="hover:bg-gray-100 transition duration-200">
//                   <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>
//                     <a 
//                       href={`https://blockstream.info/tx/${tx.txid}`} 
//                       target="_blank" 
//                       rel="noopener noreferrer" 
//                       className="text-blue-500 underline hover:text-blue-700"
//                     >
//                          {`${tx.txid.slice(0, 15)}...${tx.txid.slice(-15)}`}
//                     </a>
//                   </td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.amount}</td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.confirmed ? "Yes" : "No"}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="3" className="p-4 text-center">No transactions found</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default StatisticsTable2;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios'; 
// import "../GlobalCss.css";

// const StatisticsTable2 = () => {
//   const [txData, setTxData] = useState([]); 
//   const token = localStorage.getItem('accessToken'); 
//   const apiUrl = process.env.REACT_APP_BASE_URL;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/user/wallet/address/txs/withdrawl`, {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         });
  
//         console.log("API Response:", response.data); 
  
//         if (Array.isArray(response.data)) {
//           setTxData(response.data);
//         } else {
//           console.error("Unexpected response format");
//           setTxData([]);
//         }
//       } catch (error) {
//         console.error("Error fetching data", error);
//         setTxData([]); 
//       }
//     };
  
//     fetchData();
//   }, [token, apiUrl]);
  
//   return (
//     <div className="flex flex-col items-center pt-10 pb-10 w-full lg:w-1/2 px-4 lg:px-0">
//       <div className="overflow-x-auto w-full">
//         <table className="bg-white border shadow-md min-w-full">
//           <thead>
//             <tr>
//               <th colSpan="3" className="p-4 lg:p-8">
//                 <div className="flex justify-between">
//                   <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
//                     Withdrawal History
//                   </h1>
//                 </div>
//               </th>
//             </tr>
//             <tr>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>TXID</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Amount</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Confirmed</th>
//             </tr>
//           </thead>
//           <tbody>
//             {txData.length > 0 ? (
//               txData.map((tx, index) => (
//                 <tr key={index} className="hover:bg-gray-100 transition duration-200">
//                   <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>{tx.txid}</td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.amount}</td>
//                   <td className="p-2 lg:p-4 text-xs lg:text-base">{tx.confirmed ? "Yes" : "No"}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="3" className="p-4 text-center">No transactions found</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default StatisticsTable2;








// import React from 'react';
// import "../GlobalCss.css";
// import BTC from "../../assets/logos/bitcoin png.png";
// import ETH from "../../assets/logos/Ethereum Logo.png";
// import BTC_CASH from "../../assets/logos/bitcoin-cash-bch-logo (1).png";
// import LITCOIN from "../../assets/logos/litecoin-ltc-logo.png";
// import THETER from "../../assets/logos/tether-usdt-logo.png";
// import XRP from "../../assets/logos/xrp.png";
// import DOGE from "../../assets/logos/dogecoin.png";
// import TRX from "../../assets/logos/tron.png";
// import ADA from "../../assets/logos/cardano.png";
// import GRN from "../../assets/logos/GRN-new.webp";
// import ECA from "../../assets/logos/electra-logo.png";

// const StatisticsTable2 = () => {
//   const data = [
//     { rank: 1, image: BTC, symbol: 'BTC', name: 'Bitcoin', price: '10,830,974.34 PKR' },
//     { rank: 2, image: ETH, symbol: 'ETH', name: 'Ethereum', price: '10,830,974.34 PKR' },
//     { rank: 3, image: BTC_CASH, symbol: 'BCH', name: 'Bitcoin Cash', price: '10,830,974.34 PKR' },
//     { rank: 4, image: LITCOIN, symbol: 'LTC', name: 'Litecoin', price: '10,830,974.34 PKR' },
//     { rank: 5, image: THETER, symbol: 'USDT', name: 'Tether', price: '10,830,974.34 PKR' },
//     { rank: 6, image: XRP, symbol: 'XRP', name: 'XRP', price: '10,830,974.34 PKR' },
//     { rank: 7, image: DOGE, symbol: 'DOGE', name: 'Dogecoin', price: '10,830,974.34 PKR' },
//     { rank: 8, image: TRX, symbol: 'TRX', name: 'Tron', price: '10,830,974.34 PKR' },
//     { rank: 9, image: ADA, symbol: 'ADA', name: 'Cardano', price: '10,830,974.34 PKR' },
//     { rank: 10, image: GRN, symbol: 'GRN', name: 'GreenPower', price: '10,830,974.34 PKR' },
//     { rank: 11, image: ECA, symbol: 'ECA', name: 'Electra', price: '10,830,974.34 PKR' },
//   ];

//   return (
//     <div className="flex flex-col items-center pt-10 pb-10 w-full lg:w-1/2 px-4 lg:px-0">
//       <div className="overflow-x-auto">
//         <table className="bg-white border shadow-md min-w-full">
//           <thead>
//             <tr>
//               <th colSpan="7" className="p-4 lg:p-8">
//                 <div className="flex justify-between">
//                   <h1 className="text-lg lg:text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>
//                     Cryptocurrencies
//                   </h1>
//                 </div>
//               </th>
//             </tr>
//             <tr>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Rank</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Name</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Symbol</th>
//               <th className="p-2 lg:p-4 text-xs lg:text-sm font-medium" style={{ color: "#B0C1D3", fontFamily: 'Roboto-Regular' }}>Price</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((crypto) => (
//               <tr key={crypto.rank}>
//                 <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>{crypto.rank}</td>
//                 <td className="p-2 lg:p-4 text-xs lg:text-base">{crypto.name}</td>
//                 <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#6D859E" }}>{crypto.symbol}</td>
//                 <td className="p-2 lg:p-4 text-xs lg:text-base">{crypto.price}</td>
//                 <td className="p-2 lg:p-4 text-xs lg:text-base" style={{ color: "#2FC569" }}>{crypto.change}</td>
//                 <td className="p-2 lg:p-4 text-xs lg:text-base">{crypto.marketCap}</td>
//                 <td className="p-2 lg:p-4">
//                   <button className="text-xs lg:text-base text-white px-4 py-2 rounded" style={{ color: "#1AB6FF", background: "#edfaff" }}>
//                     Buy
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default StatisticsTable2;


// import React from 'react';
// import "../GlobalCss.css";
// import BTC from "../../assets/logos/bitcoin png.png";
// import ETH from "../../assets/logos/Ethereum Logo.png";
// import BTC_CASH from "../../assets/logos/bitcoin-cash-bch-logo (1).png";
// import LITCOIN from "../../assets/logos/litecoin-ltc-logo.png";
// import THETER from "../../assets/logos/tether-usdt-logo.png";
// import XRP from "../../assets/logos/xrp.png";
// import DOGE from "../../assets/logos/dogecoin.png";
// import TRX from "../../assets/logos/tron.png";
// import ADA from "../../assets/logos/cardano.png";
// import GRN from "../../assets/logos/GRN-new.webp";
// import ECA from "../../assets/logos/electra-logo.png";


// const  StatisticsTable = () => {
//   const data = [
//     { rank: 1,image: BTC, symbol: 'BTC', name: 'Bitcoin', price: '10,830,974.34 PKR', },
//     { rank: 2,image: ETH, symbol: 'ETH', name: 'Ethereum', price: '10,830,974.34 PKR',},
//     { rank: 3,image: BTC_CASH, symbol: 'BCH', name: 'Bitcoin Cash', price: '10,830,974.34 PKR', },
//     { rank: 4,image: LITCOIN, symbol: 'LTC', name: 'Litecoin', price: '10,830,974.34 PKR',},
//     { rank: 5,image: THETER, symbol: 'USDT', name: 'Tether', price: '10,830,974.34 PKR',},
//     { rank: 6,image: XRP, symbol: 'XRP', name: 'XRP', price: '10,830,974.34 PKR',},
//     { rank: 7,image: DOGE, symbol: 'DOGE', name: 'Dogecoin', price: '10,830,974.34 PKR', },
//     { rank: 8,image: TRX, symbol: 'TRX', name: 'Tron',  price: '10,830,974.34 PKR',},
//     { rank: 9,image: ADA, symbol: 'ADA', name: 'Cardano', price: '10,830,974.34 PKR',},
//     { rank: 10,image: GRN, symbol: 'GRN', name: 'GreenPower', price: '10,830,974.34 PKR', },
//     { rank: 11,image: ECA, symbol: 'ECA', name: 'Electra', price: '10,830,974.34 PKR', },
//   ];
  
//   // const data = [
//   //   { rank: 1,image: BTC, symbol: 'BTC', name: 'Bitcoin', price: '10,830,974.34 PKR', change: '%', marketCap: '854.36B€' },
//   //   { rank: 2,image: ETH, symbol: 'ETH', name: 'Ethereum', price: '544,581.39 PKR', change: '%', marketCap: '250.31B€' },
//   //   { rank: 3,image: BTC_CASH, symbol: 'BCH', name: 'Bitcoin Cash', price: '36,933.62 PKR', change: '%', marketCap: '4.78B€' },
//   //   { rank: 4,image: LITCOIN, symbol: 'LTC', name: 'Litecoin', price: '21,228.71 PKR', change: '%', marketCap: '5.42B€' },
//   //   { rank: 5,image: THETER, symbol: 'USDT', name: 'Tether', price: '283.27 PKR', change: '%', marketCap: '89.80B€' },
//   //   { rank: 6,image: XRP, symbol: 'XRP', name: 'XRP', price: '159.22 PKR', change: '%', marketCap: '33.58B€' },
//   //   { rank: 7,image: DOGE, symbol: 'DOGE', name: 'Dogecoin', price: '34.77 PKR', change: '%', marketCap: '13.13B€' },
//   //   { rank: 8,image: TRX, symbol: 'TRX', name: 'Tron', price: '22.75 PKR', change: '%', marketCap: '9.18B€' },
//   //   { rank: 9,image: ADA, symbol: 'ADA', name: 'Cardano', price: '10.83 PKR', change: '%', marketCap: '14.93B€' },
//   //   { rank: 10,image: GRN, symbol: 'GRN', name: 'GreenPower', price: '3.68 PKR', change: '%', marketCap: '45.19M€' },
//   //   { rank: 11,image: ECA, symbol: 'ECA', name: 'Electra', price: '0.01083 PKR', change: '%', marketCap: '0€' },
//   // ];

//   return (
//     <div className="flex flex-col items-center pt-10 pb-10 w-1/2">
//       <table className="bg-white border shadow-md ">
//         <thead>
//           <tr>
//             <th colSpan="7" className="p-8">
//               <div className="flex justify-between">
//                 <h1 className="text-2xl font-bold" style={{ fontFamily: 'Roboto-Black' }}>Cryptocurrencies</h1>
                
//               </div>
//             </th>
//           </tr>
//           <tr>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3" , fontFamily: 'Roboto-Regular'}}>Rank</th>
           
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Name</th>
//             <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Symbol</th>
//              <th className="p-4 text-sm font-medium" style={{color:"#B0C1D3", fontFamily: 'Roboto-Regular' }}>Price</th>
         
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((crypto) => (
//             <tr key={crypto.rank}>
//               <td className="p-4" style={{color:"#6D859E"}}>{crypto.rank}</td>
//               {/* <td className="p-4">
//                 <img src={crypto.image} alt={crypto.symbol} style={{ width: '30px', height: '30px' }} />
//               </td> */}
//               <td className="p-4">{crypto.name}</td>
//               <td className="p-4" style={{color:"#6D859E"}}>{crypto.symbol}</td>
//               <td className="p-4">{crypto.price}</td>
//               <td className="p-4" style={{color:"#2FC569"}}>{crypto.change}</td>
//               <td className="p-4">{crypto.marketCap}</td>
//               <td className="p-4">
//                 <button className=" text-white px-4 py-2 rounded" style={{color: "#1AB6FF", background:"#edfaff"}}>Buy</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default  StatisticsTable;


