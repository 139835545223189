import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./GlobalCss.css";

const ConfirmPassword = () => {
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setConfirmPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordToggle = () => setShowPassword(!showPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log({ email, pin, password, password_confirm });

    try {
      const response = await axios.post(`${apiUrl}/auth/password/reset`, {
        email,
        pin,
        password,
        password_confirm,
      });

      console.log("API response:", response);
      setResponseMessage(response.data.message);
    } catch (error) {
      console.error("API error:", error.response?.data || error.message);
      setResponseMessage(error.response?.data?.message || "An error occurred");
    }
  };
  return (
    <div className="flex flex-col items-center justify-center p-6">
      <div className="max-w-md w-full">
        <h2
          className="text-3xl font-semibold mb-8 text-center"
          style={{ fontFamily: "Roboto-Black" }}
        >
          Confirm Password
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <input
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <input
              placeholder="6-digit Code"
              type="text"
              id="pin"
              name="pin"
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              required
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <input
              placeholder="Confirm Password"
              type={showPassword ? "text" : "password"}
              id="password_confirm"
              name="password_confirm"
              className="mt-1 p-4 text-lg  w-full border rounded-md"
              required
              value={password_confirm}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between mb-4 w-full whitespace-nowrap">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={handleShowPasswordToggle}
                className="mr-2"
              />
              <label
                htmlFor="showPassword"
                className="text-sm"
                style={{ fontFamily: "Roboto-Regular" }}
              >
                Show Password
              </label>
            </div>
            <a
              to="/forget"
              className="text-sm hover:underline"
              style={{ fontFamily: "Roboto-Regular", color: "#1AB6FF" }}
            >
              Forgot password?
            </a>
        </div>
          <button
            type="submit"
            className=" text-white   p-4 text-lg rounded-md hover:bg-blue-600 w-full"
            style={{ fontFamily: "Roboto-Regular", background: "#1AB6FF" }}
          >
            Submit
          </button>
          {responseMessage && (
            <p
              className="text-center mt-4"
              style={{ fontFamily: "Roboto-Regular", color: "#1AB6FF" }}
            >
              {responseMessage}
            </p>
          )}
        </form>
        <div
          className="text-sm text-gray-600 mt-4 text-center"
          style={{ fontFamily: "Roboto-Regular" }}
        >
          Don’t have an account?{" "}
          <a
            href="/signup"
            className=" hover:underline"
            style={{ fontFamily: "Roboto-Black", color: "#1AB6FF" }}
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;
